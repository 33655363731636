import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "reactstrap";
// import { useTranslation } from "react-i18next";
import { FcOnlineSupport, FcPhone, FcViewDetails } from "react-icons/fc";

import no_img_innovation from "../../images/no_img_innovation.png";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { apiUrl } from "../../config/api.json";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  customLabel: {
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
  },
  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  cardlayout: {
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  tabsBg: {
    background:
      "linear-gradient(90deg,rgba(240, 99, 0, 1) 0%,rgba(255, 115, 0, 1) 38%,rgba(254, 148, 0, 1) 100%)",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-controls={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ fontFamily: "Prompt" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const ProjectInnovation = (props) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  const [innovations, setinnovations] = useState([]);
  const [product, setproduct] = useState([]);
  const [creative, setcreative] = useState([]);

  const [valuePage1, setValuePage1] = useState(0);

  const handleChangePage1 = (event, newValue) => {
    setValuePage1(newValue);
  };

  var type_table;
  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const project_id = params.get("project_id");
    if (!project_id) {
      const project_id = params.get("concep_id");
      type_table = "concept";
      return project_id;
    } else {
      type_table = "us-project";
    }
    return project_id;
  };
  const idencrypt = getParamsId();
  const getinnovation = (id) => {
    // /api/get/concept_proposal-innovation?concept_proposal_id=77&type=concept&group=innovation
    axios
      .get(
        `${apiUrl}/api/get/concept_proposal-innovation?concept_proposal_id=${id}&type=${type_table}&group=innovation`
      )
      .then((res) => {
        setinnovations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getproduct = (id) => {
    axios
      .get(`${apiUrl}/api/get/co_innovation_project?cid=${id}`)
      .then((res) => {
        setproduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getcreative = (id) => {
    axios
      .get(
        `${apiUrl}/api/get/concept_proposal-innovation?concept_proposal_id=${id}&type=${type_table}&group=creative`
      )
      .then((res) => {
        setcreative(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var id;
  useEffect(() => {
    if (idencrypt === null || idencrypt === "") {
      props.history.push({
        pathname: "/SearchProject",
      });
    } else {
      id = atob(idencrypt);
    }
    getinnovation(id);
    getproduct(id);
    getcreative(id);
  }, []);

  var innovationsdata;
  if (innovations.length > 0) {
    innovationsdata = innovations.map(function (listValue, i) {
      var url_image = "";
      if (listValue.innovation_image) {
        url_image = listValue.innovation_image;
      } else if (listValue.images.length > 0) {
        if (listValue.images[0].innovation_image_name) {
          url_image = listValue.images[0].innovation_image_name;
        }
      } else {
        url_image = no_img_innovation;
      }
      const url = `/InnovationDetail?type=innovation&innovationid=${listValue.innovation_id}&typetable=ustable`;
      return (
        <Col xs={12} sm={6} md={6} lg={4}>
          <div
            className="card card-bg-innovation card-innovation"
            style={{
              boxShadow: "14px 13px 0px -5px rgb(159 159 159 / 37%)",
              padding: "0.5rem",
              backgroundColor: "#fff",
            }}
          >
            <div className="card__image-holder">
              <img
                className="card__image img-innovation"
                src={url_image}
                onError={(e) =>
                  (e.target.onerror = null)((e.target.src = no_img_innovation))
                }
                alt="co_researcher_pi_image"
              />
            </div>
            <div
              className="card-innovation-title"
              style={{ backgroundColor: "#fff" }}
            >
              <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
                {listValue.innovation_name ? listValue.innovation_name : "-"}
              </h2>
              <small className="innovation">
                ราคา:{" "}
                {listValue.innovation_price ? listValue.innovation_price : "-"}{" "}
                บาท
              </small>
            </div>
            <div className="descriptions" style={{ backgroundColor: "#fff" }}>
              <h5 className="title-hover">
                {" "}
                {listValue.innovation_name ? listValue.innovation_name : "-"}
              </h5>
              <p>
                <FcViewDetails size={15} /> :{" "}
                {listValue.innovation_detail
                  ? listValue.innovation_detail.replace(
                      /<[^>]+>|&nbsp;|&quot;/g,
                      ""
                    )
                  : "-"}{" "}
              </p>
              <p>
                จำนวนการผลิต :{" "}
                {listValue.innovation_amount
                  ? listValue.innovation_amount
                  : "-"}{" "}
                ชิ้น
              </p>
              <p>
                <FcOnlineSupport size={15} /> :{" "}
                {listValue.co_researcher_pi_coordinator
                  ? listValue.co_researcher_pi_coordinator
                  : "-"}
              </p>
              <p>
                <FcPhone size={15} /> :{" "}
                {listValue.co_researcher_phone
                  ? listValue.co_researcher_phone
                  : "-"}
              </p>
              <Link to={url} className="btn-innovation">
                ดูเพิ่มเติม
              </Link>
            </div>
          </div>
        </Col>
      );
    });
  }

  var productdata;
  if (product.length > 0) {
    productdata = product.map(function (listValue, i) {
      var url_image = "";
      if (listValue.innovation_image) {
        url_image = listValue.innovation_image;
      } else if (listValue.images.length > 0) {
        if (listValue.images[0].innovation_image_name) {
          url_image = listValue.images[0].innovation_image_name;
        }
      } else {
        url_image = no_img_innovation;
      }
      const url = `/InnovationDetail?type=product&innovationid=${listValue.innovation_id}&typetable=ustable`;
      return (
        <Col xs={12} sm={6} md={6} lg={4}>
          <div
            className="card card-bg-innovation card-innovation"
            style={{
              boxShadow: "14px 13px 0px -5px rgb(159 159 159 / 37%)",
              padding: "0.5rem",
              backgroundColor: "#fff",
            }}
          >
            <div className="card__image-holder">
              <img
                className="card__image img-innovation"
                src={url_image}
                onError={(e) =>
                  (e.target.onerror = null)((e.target.src = no_img_innovation))
                }
                alt="co_researcher_pi_image"
              />
            </div>
            <div
              className="card-innovation-title"
              style={{ backgroundColor: "#fff" }}
            >
              <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
                {listValue.innovation_name ? listValue.innovation_name : "-"}
              </h2>
              <small className="innovation">
                ราคา:{" "}
                {listValue.innovation_price ? listValue.innovation_price : "-"}{" "}
                บาท
              </small>
            </div>
            <div className="descriptions" style={{ backgroundColor: "#fff" }}>
              <h5 className="title-hover">
                {" "}
                {listValue.innovation_name ? listValue.innovation_name : "-"}
              </h5>
              <p>
                <FcViewDetails size={15} /> :{" "}
                {listValue.innovation_detail
                  ? listValue.innovation_detail.replace(
                      /<[^>]+>|&nbsp;|&quot;/g,
                      ""
                    )
                  : "-"}{" "}
              </p>
              <p>
                จำนวนการผลิต :{" "}
                {listValue.innovation_amount
                  ? listValue.innovation_amount
                  : "-"}{" "}
                ชิ้น
              </p>
              <p>
                <FcOnlineSupport size={15} /> :{" "}
                {listValue.co_researcher_pi_coordinator
                  ? listValue.co_researcher_pi_coordinator
                  : "-"}
              </p>
              <p>
                <FcPhone size={15} /> :{" "}
                {listValue.co_researcher_pi_phone
                  ? listValue.co_researcher_pi_phone
                  : "-"}
              </p>
              <Link to={url} className="btn-innovation">
                ดูเพิ่มเติม
              </Link>
            </div>
          </div>
        </Col>
      );
    });
  }

  var creativedata;
  if (creative.length > 0) {
    creativedata = creative.map(function (listValue, i) {
      var url_image = "";
      if (listValue.innovation_image) {
        url_image = listValue.innovation_image;
      } else if (listValue.images.length > 0) {
        if (listValue.images[0].innovation_image_name) {
          url_image = listValue.images[0].innovation_image_name;
        }
      } else {
        url_image = no_img_innovation;
      }
      const url = `/InnovationDetail?type=creative&innovationid=${listValue.innovation_id}&typetable=ustable`;
      return (
        <Col xs={12} sm={6} md={6} lg={4}>
          <div
            className="card card-bg-innovation card-innovation"
            style={{
              boxShadow: "14px 13px 0px -5px rgb(159 159 159 / 37%)",
              padding: "0.5rem",
              backgroundColor: "#fff",
            }}
          >
            <div className="card__image-holder">
              <img
                className="card__image img-innovation"
                src={url_image}
                onError={(e) =>
                  (e.target.onerror = null)((e.target.src = no_img_innovation))
                }
                alt="co_researcher_pi_image"
              />
            </div>
            <div
              className="card-innovation-title"
              style={{ backgroundColor: "#fff" }}
            >
              <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
                {listValue.innovation_name ? listValue.innovation_name : "-"}
              </h2>
              <small className="innovation">
                ราคา:{" "}
                {listValue.innovation_price ? listValue.innovation_price : "-"}{" "}
                บาท
              </small>
            </div>
            <div className="descriptions" style={{ backgroundColor: "#fff" }}>
              <h5 className="title-hover">
                {" "}
                {listValue.innovation_name ? listValue.innovation_name : "-"}
              </h5>
              <p>
                <FcViewDetails size={15} /> :{" "}
                {listValue.innovation_detail
                  ? listValue.innovation_detail.replace(
                      /<[^>]+>|&nbsp;|&quot;/g,
                      ""
                    )
                  : "-"}{" "}
              </p>
              <p>
                จำนวนการผลิต :{" "}
                {listValue.innovation_amount
                  ? listValue.innovation_amount
                  : "-"}{" "}
                ชิ้น
              </p>
              <p>
                <FcOnlineSupport size={15} /> :{" "}
                {listValue.co_researcher_pi_coordinator
                  ? listValue.co_researcher_pi_coordinator
                  : "-"}
              </p>
              <p>
                <FcPhone size={15} /> :{" "}
                {listValue.co_researcher_phone
                  ? listValue.co_researcher_phone
                  : "-"}
              </p>
              <Link to={url} className="btn-innovation">
                ดูเพิ่มเติม
              </Link>
            </div>
          </div>
        </Col>
      );
    });
  }
  return (
    <div>
      <AppBar
        position="static"
        color="default"
        className={classes.appbar}
        style={{ borderRadius: "10px 10px 0 0", paddingTop: "2rem" }}
      >
        <Tabs
          value={valuePage1}
          onChange={handleChangePage1}
          variant="scrollable"
          scrollButtons="on"
          TabIndicatorProps={{
            style: { background: "#30acdc" },
          }}
          textColor="secondary"
          aria-label="scrollable tabs menu"
        >
          <Tab
            label="นวัตกรรม"
            className={classes.customLabel}
            {...a11yProps(0)}
          />

          <Tab
            label="ผลิตภัณฑ์"
            className={classes.customLabel}
            {...a11yProps(1)}
          />
          <Tab
            label="งานสร้างสรรค์"
            className={classes.customLabel}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>

      <div className="div-expertise">
        <TabPanel value={valuePage1} index={0} style={{ padding: "0 1rem" }}>
          {innovations.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                นวัตกรรมทั้งหมด {innovations.length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
              <Row>{innovationsdata}</Row>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>

        <TabPanel value={valuePage1} index={1} style={{ padding: "0 1rem" }}>
          {product.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                ผลิตภัณฑ์ทั้งหมด {product.length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
              <Row>{productdata}</Row>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>

        <TabPanel value={valuePage1} index={2} style={{ padding: "0 1rem" }}>
          {creative.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                งานสร้างสรรค์ทั้งหมด {creative.length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
              <Row>{creativedata}</Row>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>
      </div>
    </div>
  );
};

export default ProjectInnovation;
