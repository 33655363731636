import { useEffect, useState } from "react";
import axios from "axios";
import token from "../../config/token.json";

import { Row, Col } from "react-bootstrap";
import no_img_patent from "../../images/no_img_research.png";
import { Link } from "react-router-dom";

// import { FcManager } from "react-icons/fc";
// import { FaSearch } from "react-icons/fa";

import { useTranslation } from "react-i18next";
import { apiUrl } from "../../config/api.json";
export default function Property(props) {
  const { concept_proposal_id } = props;

  const { t } = useTranslation();
  const [patent, setpatent] = useState([]);
  const [loading, setloading] = useState(true);
  let id = concept_proposal_id;

  const getpatent = async () => {
    try {
      const res = await axios.get(`${apiUrl}/api/get/us-patent/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      });
      setpatent(res.data);
      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };
  useEffect(() => {
    getpatent();
  }, []);

  // console.log("paten ", patent);
  var patentdiv;
  if (patent.length > 0) {
    patentdiv = patent.map(function (listValue, i) {
      var url_image = "";
      if (listValue.patent_images || listValue.co_patent_image) {
        url_image = listValue.patent_images
          ? `https://rmuts-rc.kims-rmuti.com/file-upload/patent-upload/${listValue.patent_images}`
          : `https://rmuts-rc.kims-rmuti.com/file-upload/co_patent-upload/${listValue.co_patent_image}`;
      } else {
        url_image = no_img_patent;
      }
      var url_detail;
      url_detail = `/PatentDetail?type=researcher&patent_name=${listValue.patent_name_th}`;
      // console.log(url_image);
      return (
        <Col sm={4} md={4} lg={3} xl={3} xxl={3} key={i}>
          <div className="card card-bg-innovation card-innovation">
            <div className="card__image-holder">
              <img
                className="card__image img-innovation"
                src={url_image}
                onError={(e) =>
                  (e.target.onerror = null)((e.target.src = no_img_patent))
                }
                alt="co_innovation_image"
              />
            </div>
            <div className="card-innovation-title">
              <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
                {listValue.patent_name_th
                  ? listValue.patent_name_th
                  : listValue.co_patent_name_th}
              </h2>
              <small className="innovation">
                ประเภทสิทธิบัตร :{" "}
                {listValue.patent_type_name ? listValue.patent_type_name : "-"}
              </small>
            </div>
            <div className="descriptions">
              <h5 className="title-hover">
                {listValue.patent_name_th
                  ? listValue.patent_name_th
                  : listValue.co_patent_name_th}
              </h5>
              <p>
                {t("patent.registration_date")} :
                {listValue.patent_date
                  ? new Date(listValue.patent_date).toLocaleDateString(
                      "th-TH",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )
                  : new Date(listValue.co_patent_date).toLocaleDateString(
                      "th-TH",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
              </p>
              <p>
                {t("patent.request_number")} :
                {listValue.patent_request
                  ? listValue.patent_request
                  : listValue.co_patent_request
                  ? listValue.co_patent_request
                  : "-"}
              </p>
              <p>
                <p>
                  ประเภทสิทธิบัตร :{" "}
                  {listValue.patent_type_name
                    ? listValue.patent_type_name
                    : "-"}
                </p>
              </p>
              <Link to={url_detail} className="btn-innovation">
                {t("innovation.seemore")}
              </Link>
            </div>
          </div>
        </Col>
      );
    });
  }

  return (
    <div>
      {loading ? (
        <div className="demo"></div>
      ) : (
        <Row className="div-detail">
          {patent.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                ทรัพย์สินทางปัญญาทั้งหมด {patent.length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
              {patentdiv}
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </Row>
      )}
    </div>
  );
}
