import React, { useCallback, useState, useMemo } from "react";
import { useEventHandlers } from "@react-leaflet/core";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import "./Css/Search.scss";
import { Icon } from "leaflet";
import D3Layer from "./D3Layer";
import {
  MapContainer,
  TileLayer,
  Marker,
  CircleMarker,
  useMap,
  useMapEvent,
  Rectangle,
} from "react-leaflet";
import { Card, Row, Col, Accordion } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { apiUrl } from "../config/api.json";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import "./Css/searchresearcher.scss";
import { Link } from "react-router-dom";
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

const BOUNDS_STYLE = { weight: 1 };

function MinimapBounds({ parentMap, zoom }) {
  const minimap = useMap();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom);
  }, [minimap, parentMap, zoom]);

  // Listen to events on the parent map
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);
  useEventHandlers({ instance: parentMap }, handlers);

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
}

const SearchPageResearch = (props) => {
  const { t } = useTranslation();
  const [universitys, setUniversitys] = React.useState([]);
  const [other_universitys, setother_universitys] = React.useState([]);

  const [count_researcher, setcount_researcher] = React.useState(0);
  const [count_in_country, setcount_in_country] = React.useState(0);
  const [count_out_country, setcount_out_country] = React.useState(0);
  const [count_rmuti_university, setcount_rmuti_university] = React.useState(0);
  const [count_other_researcher, setcount_other_researcher] = React.useState(0);

  const [locationUniversity, setLocationUniversity] = useState([]);
  const [searchFullname, setSearchfullname] = React.useState("");

  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [map, setMap] = React.useState({});

  const getRequestParams = (fullname) => {
    let params = {};
    if (fullname !== undefined) {
      params["fullname"] = fullname;
    }
    return params;
  };
  const retrieveUsers = () => {
    setLoading(true);
    setUniversitys([]);
    setother_universitys([]);
    setcount_researcher(0);
    setcount_in_country(0);
    setcount_out_country(0);
    setcount_rmuti_university(0);
    setcount_other_researcher(0);
    setLocationUniversity([]);
    setMap({});
    const params = getRequestParams(searchFullname);
    // console.log("Name: ", params);
    axios
      .get(`${apiUrl}/api/get/bb-user`, { params })
      .then((res) => {
        // console.log("test1:", res.data);
        // setdata_researcher(res.data);
        // console.log("test:data: ", data_researcher);
        setMap(res.data.map);
        setcount_researcher(res.data.count_researcher);
        setcount_in_country(res.data.count_in_country);
        setcount_out_country(res.data.count_out_country);
        setcount_rmuti_university(res.data.count_rmuti_university);
        setcount_other_researcher(res.data.count_other_researcher);

        // console.log(res.data.rmuti_universitys);
        const newrmuti = res.data.rmuti_universitys.map((item) => {
          if (item.unid === 1000) {
            return {
              ...item,
              name: "มหาวิทยาลัยเทคโนโลยีราชมงคลอีสาน นครราชสีมา",
            };
          }

          return item;
        });
        setUniversitys(newrmuti);
        setother_universitys(res.data.other_universitys);
        setLocationUniversity(res.data.university_location);
      })
      .finally(() => {
        setMessage("");
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        // setMessage(e.response.data.message);
      });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    retrieveUsers();
  }, []);

  const onChangeSearchFullname = (e) => {
    setSearchfullname(e.target.value);
  };
  function MinimapControl({ position, zoom }) {
    const parentMap = useMap();
    const mapZoom = zoom || 0;

    // Memoize the minimap so it's not affected by position changes
    const minimap = useMemo(
      () => (
        <MapContainer
          style={{ height: 120, width: 200 }}
          center={parentMap.getCenter()}
          zoom={mapZoom}
          dragging={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          attributionControl={false}
          zoomControl={false}
        >
          {locationUniversity.map((p, index) => (
            <CircleMarker
              key={index}
              center={[p.lat ? p.lat : 0, p.lon ? p.lon : 0]}
              // radius={10}
              opacity={0}
            >
              <Marker
                position={[p.lat ? p.lat : 0, p.lon ? p.lon : 0]}
                icon={
                  new Icon({
                    iconUrl: p.img,
                    iconSize: [10, 11],
                    // iconAnchor: [19, 0],
                    className: "minimap-image-icon",
                  })
                }
              >
                <IconButton
                  color="primary"
                  aria-label="view info co"
                  style={{
                    marginTop: "10px",
                    fontFamily: "Prompt",
                    fontSize: "15px",
                  }}
                ></IconButton>
              </Marker>
            </CircleMarker>
          ))}
          {/* <D3Layer location={map} /> */}

          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
        </MapContainer>
      ),
      []
    );

    const positionClass =
      (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
    return (
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar">{minimap}</div>
      </div>
    );
  }

  // console.log("universitys ", universitys);

  return (
    <div className="body-detail">
      <div className="card-searcher">
        <Row>
          <Col sm={12}>
            <Card className="card-border card-search">
              <Card.Body style={{ padding: "10px" }}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      m: 2,
                      width: "100%",
                      marginTop: "10px",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <Row className="align-items-center justify-content-md-center">
                      <Col md="6" xs="12">
                        <TextField
                          id="standard-helperText"
                          label={t("researcher_search.name")}
                          defaultValue="Default Value"
                          helperText={t("researcher_search.please_name")}
                          InputProps={{ style: { fontFamily: "Prompt" } }}
                          InputLabelProps={{
                            style: { fontFamily: "Prompt" },
                          }}
                          FormHelperTextProps={{
                            style: { fontFamily: "Prompt" },
                          }}
                          variant="standard"
                          value={searchFullname}
                          onChange={onChangeSearchFullname}
                        />
                      </Col>
                      <Col md="1" xs="12">
                        <Button
                          className="btn-search"
                          variant="contained"
                          type="submit"
                          size="large"
                          onClick={retrieveUsers}
                          disabled={loading}
                          startIcon={<FaSearch size={13} />}
                        >
                          {loading && <CircularProgress size={22} />}
                          {!loading && t("search")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Box>
              </Card.Body>
            </Card>
          </Col>
          {loading && <div class="demo" />}
          {!loading && (
            <>
              <Col sm={12}>
                <Card
                  className="card-border"
                  style={{
                    marginTop: "10px",
                    fontFamily: "Prompt",
                    borderRadius: "15px",
                    boxShadow: "none",
                    border: "none",
                  }}
                >
                  <MapContainer
                    center={[13, 105]}
                    zoom={5}
                    zoomControl={false}
                    style={{
                      width: "100%",
                      minHeight: "450px",
                      height: "65vh",
                      margin: "0",
                      zIndex: "0",
                      borderRadius: "15px",
                    }}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {Object.keys(map).length > 0 ? (
                      <D3Layer location={map} />
                    ) : null}
                    <MinimapControl position="topright" />
                  </MapContainer>
                </Card>
              </Col>
              <Col sm={12}>
                <div
                  className="dashboard"
                  style={{ marginTop: "-4.5rem", marginBottom: "-1rem" }}
                >
                  <div className="all-card">
                    <Row style={{ paddingLeft: "10px" }}>
                      <Col>
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title text-title">
                              {t("dashboard.rmuti")}
                            </h5>
                            <h2 className="card-text text-amount">
                              {count_rmuti_university} {t("dashboard.campuses")}
                            </h2>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title text-title">
                              {t("dashboard.researcher_rmuti")}
                            </h5>
                            <h2 className="card-text text-amount">
                              {count_researcher} {t("dashboard.people")}
                            </h2>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="card">
                          <div
                            className="card-body "
                            style={{ padding: "1rem 0.5rem" }}
                          >
                            <h5 className="card-title text-title">
                              {t("dashboard.researcher_network")}
                            </h5>
                            <h2 className="card-text text-amount">
                              {count_other_researcher} {t("dashboard.people")}
                            </h2>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title text-title">
                              {t("dashboard.domestic")}
                            </h5>
                            <h2 className="card-text text-amount">
                              {count_in_country} {t("dashboard.people")}
                            </h2>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title text-title">
                              {t("dashboard.outside")}
                            </h5>
                            <h2 className="card-text text-amount">
                              {count_out_country} {t("dashboard.people")}
                            </h2>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <Accordion
                  className="bg-title body-universitys"
                  defaultActiveKey="0"
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <Accordion.Header>
                      <h3
                        style={{
                          textAlign: "left",
                          color: "#212529",
                          marginBottom: "2rem",
                        }}
                      >
                        {t("dashboard.researcher_of_rmuti")}
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row style={{ paddingLeft: "1rem" }}>
                        {universitys.length ? (
                          <>
                            {universitys.map((list, index) => (
                              <>
                                {list.data.length ? (
                                  <Col
                                    key={index}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                    xxl={4}
                                    style={{ paddingBottom: "1.5rem" }}
                                  >
                                    <div
                                      className="card-university"
                                      style={{
                                        backgroundColor: "#fff",
                                        borderRadius: "15px",
                                      }}
                                    >
                                      <div
                                        className="card-header"
                                        style={{
                                          padding: "1.5rem 1.5rem 0px",
                                          borderRadius: "15px  15px 0 0",
                                        }}
                                      >
                                        <h5>{list.name}</h5>
                                        <p
                                          style={{
                                            textAlign: "left",
                                            marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                          }}
                                        >
                                          {t("dashboard.amount")}{" "}
                                          {list.data.length}{" "}
                                          {t("dashboard.people")}
                                        </p>
                                      </div>
                                      <div className="card-university-body">
                                        <div className="list">
                                          {list.data
                                            .sort((x, y) =>
                                              x.fullname.localeCompare(
                                                y.fullname,
                                                "th"
                                              )
                                            )
                                            .map((listdata, index) => (
                                              <div
                                                className="link_feature"
                                                key={index}
                                              >
                                                <Link
                                                  to={`/Researcher/locations?user_idcard=${btoa(
                                                    listdata.user_idcard
                                                  )}`}
                                                  className="linkexternal"
                                                >
                                                  <Row
                                                    className="p-2 align-items-center justify-content-md-center"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <Col sm={2} md={2}>
                                                      <img
                                                        className="rounded-circle mx-auto d-block img-researcher"
                                                        width={40}
                                                        height={40}
                                                        src={
                                                          listdata.user_image_user
                                                            ? `https://rmuts-rc.kims-rmuti.com/file-upload/images-profile-upload/${listdata.user_image_user}`
                                                            : "https://researcher.kims-rmuti.com/icon/researcher.png"
                                                        }
                                                        alt="user_image"
                                                      />
                                                    </Col>
                                                    <Col sm={10} md={10}>
                                                      {listdata.prefix_id}
                                                      {listdata.fullname}
                                                    </Col>
                                                  </Row>
                                                </Link>
                                                <hr className="line-researcher" />
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                ) : null}
                              </>
                            ))}
                          </>
                        ) : (
                          <div className="pt-4"> {t("notfound")}</div>
                        )}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col md={12} style={{ marginTop: "2rem" }}>
                <Accordion
                  className="bg-title body-universitys"
                  defaultActiveKey="0"
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <Accordion.Header>
                      <h3
                        style={{
                          textAlign: "left",
                          color: "#212529",
                          marginBottom: "2rem",
                        }}
                      >
                        {t("dashboard.researcher_of_network")}
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row style={{ paddingLeft: "1rem" }}>
                        {other_universitys.length ? (
                          <>
                            {other_universitys
                              .sort((x, y) =>
                                x.name.localeCompare(y.name, "th")
                              )
                              .map((list, index) => (
                                <Col
                                  key={index}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                  xxl={4}
                                  style={{ paddingBottom: "1.5rem" }}
                                >
                                  <div
                                    className="card-university"
                                    style={{
                                      backgroundColor: "#fff",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <div
                                      className="card-header"
                                      style={{
                                        padding: "1.5rem 1.5rem 0px",
                                        borderRadius: "15px  15px 0 0",
                                      }}
                                    >
                                      <h5>{list.name}</h5>
                                      <p
                                        style={{
                                          textAlign: "left",
                                          marginTop: "0.5rem",
                                          marginBottom: "0.5rem",
                                        }}
                                      >
                                        {t("dashboard.amount")}{" "}
                                        {list.data.length}{" "}
                                        {t("dashboard.people")}
                                      </p>
                                    </div>
                                    <div className="card-university-body">
                                      {list.data.length ? (
                                        <div className="list">
                                          {list.data
                                            .sort((x, y) =>
                                              x.fullname.localeCompare(
                                                y.fullname,
                                                "th"
                                              )
                                            )
                                            .map((listdata, index) => (
                                              <div
                                                className="link_feature"
                                                key={index}
                                              >
                                                <Link
                                                  to={`/Researcher/locations?user_idcard=${btoa(
                                                    listdata.user_idcard
                                                  )}`}
                                                  className="linkexternal"
                                                >
                                                  <Row
                                                    className="p-2 align-items-center justify-content-md-center"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <Col sm={2} md={2}>
                                                      <img
                                                        className="rounded-circle mx-auto d-block img-researcher"
                                                        width={40}
                                                        height={40}
                                                        src={
                                                          listdata.user_image_user
                                                            ? `https://rmuts-rc.kims-rmuti.com/file-upload/images-profile-upload/${listdata.user_image_user}`
                                                            : "https://researcher.kims-rmuti.com/icon/researcher.png"
                                                        }
                                                        alt="user_image"
                                                      />
                                                    </Col>
                                                    <Col sm={10} md={10}>
                                                      {listdata.prefix_id}
                                                      {listdata.fullname}
                                                    </Col>
                                                  </Row>
                                                </Link>
                                                <hr className="line-researcher" />
                                              </div>
                                            ))}
                                        </div>
                                      ) : (
                                        <div className="list pt-4">
                                          {t("notfound")}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              ))}
                          </>
                        ) : (
                          <div className="pt-4"> {t("notfound")}</div>
                        )}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default withRouter(SearchPageResearch);
