import { CardTitle } from "reactstrap";
import { BiInfoCircle } from "react-icons/bi";
import { IconButton } from "@material-ui/core";
import { Row, Col, Accordion } from "react-bootstrap";
import { Icon } from "leaflet";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  Marker,
  Popup,
} from "react-leaflet";
// import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import token from "../../config/token.json";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config/api.json";
// import L from "leaflet";
// import MarkerClusterGroup from "react-leaflet-markercluster";

export default function ResearcherLocation() {
  // const { t } = useTranslation();
  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("user_idcard");
  };
  const idencrypt = getParamsId();

  const [research, setresearch] = useState([]);
  const [academic, setacademic] = useState([]);
  const [u2t, setu2t] = useState([]);
  // const [u2tbcg, setu2tbcg] = useState([]);

  const [researchteam, setresearchteam] = useState([]);
  const [academicteam, setacademicteam] = useState([]);
  const [u2tteam, setu2tteam] = useState([]);
  // const [u2tbcgteam, setu2tbcgteam] = useState([]);

  const [dataMap, setdataMap] = useState([]);
  const [map, setmap] = useState([]);
  // const localUrl = "http://localhost:4000";

  // const createClusterCustomIcon = (cluster) => {
  //   const count = cluster.getChildCount();
  //   let size = "LargeXL";
  //   let iconSize = 70;

  //   if (cluster && count > 2) {
  //     L.polygon(cluster.getConvexHull());
  //   }

  //   if (count < 20) {
  //     size = "Small";
  //     iconSize = 40;
  //   } else if (count >= 20 && count < 100) {
  //     size = "Medium";
  //     iconSize = 45;
  //   } else if (count >= 100 && count < 500) {
  //     size = "Large";
  //     iconSize = 55;
  //   }
  //   const options = {
  //     cluster: `markerCluster${size}`,
  //     isize: iconSize,
  //   };

  //   return L.divIcon({
  //     html: `<div>
  //         <span class="markerClusterLabel">${count}</span>
  //       </div>`,
  //     className: `${options.cluster}`,
  //     iconSize: L.point(options.isize, options.isize, true),
  //   });
  // };

  let id = idencrypt;
  const mapData = async () => {
    const response = await axios.get(
      `${apiUrl}/api/get/us-projects-map/${id}`,
      {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      }
    );
    setmap(response.data.projects_location);
    const research = response.data.projects.filter(
      (item) =>
        parseInt(item.project_type_id) === 1 ||
        parseInt(item.project_type_id) === 3
    );
    const acdemic = response.data.projects.filter(
      (item) =>
        parseInt(item.project_type_id) === 2 ||
        parseInt(item.project_type_id) === 4
    );
    const u2t = response.data.projects.filter(
      (item) =>
        parseInt(item.project_type_id) === 5 ||
        parseInt(item.project_type_id) === 7
    );
    const researchteam = response.data.project_team.filter(
      (item) =>
        parseInt(item.project_type_id) === 1 ||
        parseInt(item.project_type_id) === 3
    );
    const acdemicteam = response.data.project_team.filter(
      (item) =>
        parseInt(item.project_type_id) === 2 ||
        parseInt(item.project_type_id) === 4
    );
    const u2tteam = response.data.project_team.filter(
      (item) =>
        parseInt(item.project_type_id) === 5 ||
        parseInt(item.project_type_id) === 7
    );
    setdataMap(response.data);
    setresearch(research);
    setacademic(acdemic);
    setu2t(u2t);
    setresearchteam(researchteam);
    setacademicteam(acdemicteam);
    setu2tteam(u2tteam);
  };

  useEffect(() => {
    mapData();
  }, []);

  const DetailPageProject = (obj) => {
    let { project } = obj;
    return (
      <div className="list">
        {project.map((listdata, index) => (
          <div className="link_feature" key={index}>
            <Link
              to={
                listdata.type === "concept_proposal"
                  ? `/ProjectDetailConcep/ProjectNetwork?concep_id=${btoa(
                      listdata.project_id
                    )}`
                  : `/ProjectDetail/ProjectNetwork?project_id=${btoa(
                      listdata.project_id
                    )}`
              }
              className="linkexternal"
            >
              <Row
                className="p-2 align-items-center justify-content-md-center"
                style={{ width: "100%" }}
              >
                <Col md="2">
                  <img
                    className="rounded-circle mx-auto d-block"
                    width={40}
                    height={40}
                    src={
                      listdata.project_type_id === 1 ||
                      listdata.project_type_id === 3
                        ? "https://researcher.kims-rmuti.com/icon/res@300x.png"
                        : listdata.project_type_id === 2 ||
                          listdata.project_type_id === 4
                        ? "https://researcher.kims-rmuti.com/icon/AS.jpg"
                        : listdata.project_type_id === 5
                        ? "https://researcher.kims-rmuti.com/icon/U2T.jpg"
                        : listdata.project_type_id === 7
                        ? "https://researcher.kims-rmuti.com/icon/U2Tbcg.jpg"
                        : null
                    }
                    alt="researcher_image"
                  />
                </Col>
                <Col md="10">
                  {listdata.project_name_th
                    ? listdata.project_name_th
                    : listdata.concept_proposal_name_th
                    ? listdata.concept_proposal_name_th
                    : null}
                </Col>
              </Row>
            </Link>
            <hr
              style={{
                margin: "0.5rem 2rem",
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const AllDetailProject = (obj) => {
    let { title, research, academic, u2t } = obj;
    return (
      <Col sm={12}>
        <Accordion
          className="bg-title  col-project-detail body-universitys"
          defaultActiveKey="0"
        >
          <Accordion.Item
            eventKey="0"
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <Accordion.Header>
              <h2
                style={{
                  textAlign: "left",
                  color: "#212529",
                  marginBottom: "2rem",
                }}
              >
                {title}
              </h2>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                {research.length ? (
                  <>
                    {" "}
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={4}
                      xxl={4}
                      style={{ paddingBottom: "1.5rem" }}
                    >
                      <div
                        className="card-university"
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "15px",
                        }}
                      >
                        <div
                          className="card-header"
                          style={{
                            padding: "1.5rem 1.5rem 0px",
                            borderRadius: "15px  15px 0 0",
                          }}
                        >
                          <h5>งานวิจัย</h5>
                          <p
                            style={{
                              textAlign: "left",
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            จำนวน {research.length} โครงการ
                          </p>
                        </div>
                        <div className="card-university-body">
                          {research.length && research ? (
                            <DetailPageProject
                              project={research}
                              type_project="research"
                            />
                          ) : (
                            <div className="list pt-4">ไม่พบข้อมูล</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                ) : null}

                {academic.length ? (
                  <>
                    {" "}
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={4}
                      xxl={4}
                      style={{ paddingBottom: "1.5rem" }}
                    >
                      <div
                        className="card-university"
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "15px",
                        }}
                      >
                        <div
                          className="card-header"
                          style={{
                            padding: "1.5rem 1.5rem 0px",
                            borderRadius: "15px  15px 0 0",
                          }}
                        >
                          <h5>งานบริการวิชาการ</h5>
                          <p
                            style={{
                              textAlign: "left",
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            จำนวน {academic.length} โครงการ
                          </p>
                        </div>
                        <div className="card-university-body">
                          {academic.length && academic ? (
                            <DetailPageProject
                              project={academic}
                              type_project="academic"
                            />
                          ) : (
                            <div className="list pt-4">ไม่พบข้อมูล</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                ) : null}

                {u2t.length ? (
                  <>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={4}
                      xxl={4}
                      style={{ paddingBottom: "1.5rem" }}
                    >
                      <div
                        className="card-university"
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "15px",
                        }}
                      >
                        <div
                          className="card-header"
                          style={{
                            padding: "1.5rem 1.5rem 0px",
                            borderRadius: "15px  15px 0 0",
                          }}
                        >
                          <h5>งานบริการวิชาการ (U2T)</h5>
                          <p
                            style={{
                              textAlign: "left",
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            จำนวน {u2t.length} โครงการ
                          </p>
                        </div>
                        <div className="card-university-body">
                          {u2t.length && u2t ? (
                            <DetailPageProject
                              project={u2t}
                              type_project="u2t"
                            />
                          ) : (
                            <div className="list pt-4">ไม่พบข้อมูล</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    );
  };
  return (
    <Row className="row-innovation">
      <Col sm={12}>
        <MapContainer
          center={[15, 103]}
          zoom={6}
          scrollWheelZoom={true}
          zoomControl={false}
          style={{
            width: "100%",
            minHeight: "450px",
            height: "70vh",
            margin: "0",
            zIndex: "0",
            borderRadius: "15px",
          }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl position="topright" />
          {map.map((p, index) => {
            return (
              <Marker
                position={[
                  p.project_latitude ? p.project_latitude : 0,
                  p.project_longitude ? p.project_longitude : 0,
                ]}
                icon={
                  new Icon({
                    iconUrl:
                      parseInt(p.project_type_id) === 1
                        ? "https://researcher.kims-rmuti.com/icon/res@300x.png"
                        : parseInt(p.project_type_id) === 2
                        ? "https://researcher.kims-rmuti.com/icon/AS.jpg"
                        : parseInt(p.project_type_id) === 5
                        ? "https://researcher.kims-rmuti.com/icon/U2T.jpg"
                        : parseInt(p.project_type_id) === 7
                        ? "https://researcher.kims-rmuti.com/icon/U2Tbcg.jpg"
                        : "https://cdn1.iconfinder.com/data/icons/social-media-set/24/Reverbnation-128.png",
                    iconSize: [40, 41],
                    className: "image-icon",
                  })
                }
              >
                <Popup>
                  <CardTitle class="  ">
                    <text
                      style={{
                        marginTop: "10px",
                        fontFamily: "Prompt",
                        fontSize: "20px",
                      }}
                    >
                      {p.project_type}
                    </text>
                    <hr />

                    <text
                      style={{
                        marginTop: "10px",
                        fontFamily: "Prompt",
                        fontSize: "15px",
                      }}
                    >
                      {p.project_name_th}
                    </text>
                    <hr />

                    <Link
                      to={
                        p.type === "concept_proposal"
                          ? `/ProjectDetailConcep/ProjectNetwork?concep_id=${btoa(
                              p.project_id
                            )}`
                          : `/ProjectDetail/ProjectNetwork?project_id=${btoa(
                              p.project_id
                            )}`
                      }
                      className="linkexternal"
                    >
                      <IconButton
                        color="primary"
                        aria-label="view info co"
                        style={{
                          marginTop: "10px",
                          fontFamily: "Prompt",
                          fontSize: "15px",
                        }}
                      >
                        รายละเอียดเพิ่มเติม <BiInfoCircle size={18} />
                      </IconButton>
                    </Link>
                  </CardTitle>
                </Popup>
              </Marker>
              // </CircleMarker>
            );
          })}
        </MapContainer>
      </Col>
      <Col sm={12}>
        <div
          className="dashboard"
          style={{ marginTop: "-4.5rem", marginBottom: "-1rem" }}
        >
          <div className="all-card">
            <Row style={{ marginLeft: "0.5rem" }}>
              <Col>
                <div
                  className="card"
                  style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                >
                  <div className="card-body ">
                    <h5 className="card-title text-title">
                      โครงการวิจัยทั้งหมด
                    </h5>
                    <h2 className="card-text text-amount">
                      {dataMap.count_projects ? dataMap.count_projects : 0}{" "}
                      โครงการ
                    </h2>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="card"
                  style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                >
                  <div className="card-body ">
                    <h5 className="card-title text-title">งานวิจัย</h5>
                    <h2 className="card-text text-amount">
                      {dataMap.count_research ? dataMap.count_research : 0}{" "}
                      โครงการ
                    </h2>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="card"
                  style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                >
                  <div className="card-body ">
                    <h5 className="card-title text-title">งานบริการวิชาการ</h5>
                    <h2 className="card-text text-amount">
                      {dataMap.count_academic ? dataMap.count_academic : 0}{" "}
                      โครงการ{" "}
                    </h2>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="card"
                  style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                >
                  <div className="card-body ">
                    <h5 className="card-title text-title">
                      งานบริการวิชาการ(U2T)
                    </h5>
                    <h2 className="card-text text-amount">
                      {dataMap.count_u2t ? dataMap.count_u2t : 0} โครงการ{" "}
                    </h2>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="card"
                  style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                >
                  <div className="card-body ">
                    <h5 className="card-title text-title">
                      งานบริการวิชาการ(U2T For BCG)
                    </h5>
                    <h2 className="card-text text-amount">
                      {dataMap.count_u2t_bcg ? dataMap.count_u2t_bcg : 0}{" "}
                      โครงการ{" "}
                    </h2>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <AllDetailProject
        title="โครงการวิจัย"
        research={research}
        academic={academic}
        u2t={u2t}
      />
      <div style={{ marginTop: "2rem" }}>
        <AllDetailProject
          title="โครงการวิจัยเครือข่าย"
          research={researchteam}
          academic={academicteam}
          u2t={u2tteam}
        />
      </div>
    </Row>
  );
}
