import { Row, Col, Card } from "reactstrap";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  Box,
  TextField,
  Button,
  Container,
  CircularProgress,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { FcManager } from "react-icons/fc";
import { FaSearch } from "react-icons/fa";
import "@splidejs/react-splide/dist/css/splide.min.css";

import { withRouter } from "react-router";
import axios from "axios";

import background_product from "../../images/bg_product.png";

import no_img_research from "../../images/no_img_research.png";
import no_img_co_research from "../../images/no_img_co_research.png";
import no_img_network from "../../images/no_img_network.png";

import { useTranslation } from "react-i18next";

import "../Css/innovation.scss";
import { Link } from "react-router-dom";
import Divloading from "../Loading/Divloading";
import { apiUrl } from "../../config/api.json";
// import $ from "jquery";

function InnovationAll(props) {
  const [patentlist, setpatentlist] = useState([]);
  const [patentgroup, setpatentgroup] = useState([]);
  const [message, setMessage] = useState("");
  const [patent, setpatent] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [divloading, setdivLoading] = useState(false);
  const [itemsPerPage, setitemsPerPage] = useState(8);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const { t } = useTranslation();

  var url_all;
  var url_find;
  var url_group;
  // var label_title;
  // var label_search;
  // var label_group;
  var no_img_patent;

  const getParamsType = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("type");
  };

  const type = getParamsType();
  if (type === "researcher") {
    url_all = `${apiUrl}/api/get/patent?patent_type_id=&title=`;
    url_find = `${apiUrl}/api/get/patent`;
    url_group = `${apiUrl}/api/get/us-patent/patent-type`;
    // label_title = "innovation.innovation";
    // label_search = "innovation.label_innovation";
    // label_group = "innovation.label_innovation_group";
    no_img_patent = no_img_research;
  } else if (type === "commuity") {
    url_all = `${apiUrl}/api/get/co-researcher/commupatent/list?title=&patent_type_id=`;
    url_find = `${apiUrl}/api/get/co-researcher/commupatent/list`;
    url_group = `${apiUrl}/api/get/us-patent/patent-type`;
    // label_title = "innovation.product";
    // label_search = "innovation.label_product";
    // label_group = "innovation.label_product_group";
    no_img_patent = no_img_co_research;
  } else if (type === "conetwork") {
    url_all = `${apiUrl}/api/get/co-researcher/cooppatent/list?title=&patent_type_id=`;
    url_find = `${apiUrl}/api/get/co-researcher/cooppatent/list`;
    url_group = `${apiUrl}/api/get/us-patent/patent-type`;
    // label_title = "innovation.creative";
    // label_search = "innovation.label_creative";
    // label_group = "innovation.label_creative_group";
    no_img_patent = no_img_network;
  }
  const getPatentlist = () => {
    axios
      .get(url_all)
      .then((res) => {
        setpatentlist(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getpatentGroup = () => {
    axios
      .get(url_group)
      .then((res) => {
        setpatentgroup(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRequestParams = (title, patent_group) => {
    let params = {};
    if (patent_group) {
      if (type === "researcher") {
        params["patent_type_id"] = patent_group;
      } else if (type === "conetwork") {
        params["patent_type_id"] = patent_group;
      }
    }
    if (title !== undefined) {
      params["title"] = title;
    }
    return params;
  };

  const retrievepatent = () => {
    setLoading(true);
    setdivLoading(true);
    const params = getRequestParams(searchTitle, patent);
    // console.log("testParam", params);
    axios
      .get(url_find, { params })
      .then((res) => {
        // console.log(res.data);
        setpatentlist(res.data);
        setdivLoading(false);
      })
      .finally(() => {
        setMessage("");
        setLoading(false);
        setdivLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setMessage(e.response.data.message);
        setpatentlist([]);
        setdivLoading(false);
      });
  };

  useEffect(() => {
    getPatentlist();
    retrievepatent();
    getpatentGroup();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      patentlist?.slice(
        patentlist?.length < itemsPerPage ? 0 : itemOffset,
        endOffset
      )
    );
    setPageCount(Math.ceil(patentlist.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, patentlist]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % patentlist.length;
    setItemOffset(newOffset);
    window.scrollTo(0, 0);
  };

  const handleChangePatent = (event) => {
    setpatent(event.target.value);
  };

  const onChangeTitle = (e) => {
    setSearchTitle(e.target.value);
  };

  let patentdata = currentItems?.map(function (listValue, i) {
    var url_image = "";
    if (listValue.patent_images || listValue.co_patent_image) {
      url_image = listValue.patent_images
        ? `https://rmuts-rc.kims-rmuti.com/file-upload/patent-upload/${listValue.patent_images}`
        : `https://rmuts-rc.kims-rmuti.com/file-upload/co_patent-upload/${listValue.co_patent_image}`;
      // } else if (listValue.images.length > 0) {
      //   if (listValue.images[0].innovation_image_name) {
      //     url_image = listValue.images[0].innovation_image_name;
      //   }
    } else {
      url_image = no_img_patent;
    }
    var url_detail;

    if (type === "researcher") {
      url_detail = `/PatentDetail?type=researcher&patent_name=${listValue.patent_name_th}`;
    } else if (type === "community") {
      url_detail = `/PatentDetail?type=community&patent_name=${listValue.patent_name_th}`;
    } else if (type === "conetwork") {
      url_detail = `/PatentDetail?type=conetwork&patent_name=${listValue.co_patent_name_th}`;
    }

    // console.log(url_image);
    return (
      <Col sm={4} md={4} lg={3} xl={3} xxl={2} key={i}>
        <div className="card card-bg-innovation card-innovation">
          <div className="card__image-holder">
            <img
              className="card__image img-innovation"
              src={url_image}
              onError={(e) =>
                (e.target.onerror = null)((e.target.src = no_img_research))
              }
              alt="co_innovation_image"
            />
          </div>
          <div className="card-innovation-title">
            <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
              {listValue.patent_name_th
                ? listValue.patent_name_th
                : listValue.co_patent_name_th}
            </h2>
            <small className="innovation">
              <FcManager size={15} /> :{" "}
              {listValue.patent_application
                ? listValue.patent_application
                : `${listValue.coordinator_prefix}${listValue.coordinator_name_th} ${listValue.coordinator_lastname_th}`}
            </small>
          </div>
          <div className="descriptions">
            <h5 className="title-hover">
              {listValue.patent_name_th
                ? listValue.patent_name_th
                : listValue.co_patent_name_th}
            </h5>
            <p>
              {t("patent.registration_date")} :
              {listValue.patent_date
                ? new Date(listValue.patent_date).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : new Date(listValue.co_patent_date).toLocaleDateString(
                    "th-TH",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
            </p>
            <p>
              {t("patent.request_number")} :
              {listValue.patent_request
                ? listValue.patent_request
                : listValue.co_patent_request
                ? listValue.co_patent_request
                : "-"}
            </p>
            <p>
              <p>
                {t("patent.registration_applicant")} :{" "}
                {listValue.patent_application
                  ? listValue.patent_application
                  : `${listValue.coordinator_prefix}${listValue.coordinator_name_th} ${listValue.coordinator_lastname_th}`}
              </p>
            </p>
            <Link to={url_detail} className="btn-innovation">
              {t("innovation.seemore")}
            </Link>
          </div>
        </div>
      </Col>
    );
  });

  return (
    <div className="body-detail">
      <div className="div-innovation-all">
        <div>
          <div className="bg-filter bg-innovation-all">
            <Card className=" search-innovation">
              <Container style={{ paddingBottom: "15px" }}>
                <div>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": {
                        m: 2,
                        width: "100%",
                        marginTop: "10px",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <Row className="align-items-center justify-content-md-center">
                        <Col md="5" xs="12">
                          <TextField
                            id="standard-helperText"
                            label={t("patent.patent")}
                            defaultValue="Default Value"
                            helperText={t("patent.label_patent")}
                            InputProps={{ style: { fontFamily: "Prompt" } }}
                            InputLabelProps={{
                              style: { fontFamily: "Prompt" },
                            }}
                            FormHelperTextProps={{
                              style: { fontFamily: "Prompt" },
                            }}
                            variant="standard"
                            value={searchTitle}
                            onChange={onChangeTitle}
                          />
                        </Col>

                        <Col md="4" xs="12">
                          <TextField
                            id="outlined-select-currency-native"
                            select
                            label=" "
                            value={patent}
                            InputLabelProps={{
                              style: { fontFamily: "Prompt" },
                            }}
                            FormHelperTextProps={{
                              style: { fontFamily: "Prompt" },
                            }}
                            onChange={handleChangePatent}
                            SelectProps={{
                              native: true,
                              style: { fontFamily: "Prompt" },
                            }}
                            helperText={t("patent.label_patent_group")}
                          >
                            <option value={""} style={{ fontFamily: "Prompt" }}>
                              แสดงทั้งหมด
                            </option>
                            {patentgroup.map((option, i) => {
                              if (type === "researcher") {
                                return (
                                  <option
                                    key={i}
                                    value={option.patent_type_id}
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    {option.patent_type_name}
                                  </option>
                                );
                              } else if (type === "community") {
                                return (
                                  <option
                                    key={i}
                                    value={option.patent_type_id}
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    {option.patent_type_name}
                                  </option>
                                );
                              } else if (type === "conetwork") {
                                return (
                                  <option
                                    key={i}
                                    value={option.patent_type_id}
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    {option.patent_type_name}
                                  </option>
                                );
                              } else {
                                return <></>;
                              }
                            })}
                          </TextField>
                        </Col>

                        <Col md="2" xs="12">
                          <Button
                            className="btn-search"
                            variant="contained"
                            size="large"
                            onClick={retrievepatent}
                            style={{
                              width: "100%",
                            }}
                            disabled={loading}
                            type="submit"
                            startIcon={<FaSearch size={13} />}
                          >
                            {loading && <CircularProgress size={22} />}
                            {!loading && t("search")}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Box>
                </div>
              </Container>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h5
                    style={{
                      color: "#242424",
                      paddingLeft: "30px",
                      paddingTop: "5px",
                      textAlign: "left",
                    }}
                  >
                    ข้อมูลทั้งหมด{" "}
                    {patentlist.length > 0 ? patentlist.length : 0} รายการ{" "}
                  </h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginRight: "3rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  <p>เลือกแสดง</p>
                  <Form.Select
                    style={{
                      width: "90px",
                      height: "30px",
                      margin: "0 0.5rem",
                    }}
                    size="sm"
                    onChange={(e) => setitemsPerPage(parseInt(e.target.value))}
                    defaultValue="8"
                  >
                    <option value="4">4</option>
                    <option value="8">8</option>
                    <option value="12">12</option>
                    <option value="52">52</option>
                    <option value="100">100</option>
                    <option value={patentlist.length}>ทั้งหมด</option>
                  </Form.Select>
                  <p>รายการ </p>
                </div>
              </div>
            </Card>
            {message ? (
              <p className="p-4" style={{ fontFamily: "Prompt" }}>
                {t("notfound")}
              </p>
            ) : (
              <>
                {divloading ? (
                  <Divloading />
                ) : (
                  <div className="cards">
                    <Row style={{ width: "100%" }}>{patentdata}</Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <ReactPaginate
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        nextLabel="ถัดไป >"
                        previousLabel="< ก่อนหน้า"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(InnovationAll);
