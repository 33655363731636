import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Col, Row } from "reactstrap";
import { useEffect, useState } from "react";
import axios from "axios";

import { FcOnlineSupport, FcPhone, FcViewDetails } from "react-icons/fc";
import no_img_innovation from "../../images/no_img_innovation.png";
import no_img_product from "../../images/no_img_product.png";
import no_img_creative from "../../images/no_img_creative.png";
import token from "../../config/token.json";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config/api.json";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-controls={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ fontFamily: "Prompt" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function InnovationsPage(props) {
  const { classes, concept_proposal_id } = props;
  const [valuePage1, setValuePage1] = useState(0);
  const handleChangePage1 = (event, newValue) => {
    setValuePage1(newValue);
  };
  const [innovation, setinnovation] = useState([]);
  const [cretive, setcreative] = useState([]);
  const [product, setproduct] = useState([]);
  // const localUrl = "http://localhost:4000";
  let id = concept_proposal_id;

  const getinnovations = async () => {
    const result = await axios.get(
      `${apiUrl}/api/get/us_innovation?id=${id}&innovation_group_id=1`,
      {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      }
    );
    // console.log("ino :", result);
    setinnovation(result.data);
  };

  const getproduct = async () => {
    const result = await axios.get(`${apiUrl}/api/get/co_innovation?id=${id}`, {
      headers: {
        "content-type": "application/json",
        "x-access-token": token.accesstoken,
      },
    });
    setproduct(result.data);
  };

  const getcreative = async () => {
    const result = await axios.get(
      `${apiUrl}/api/get/us_innovation?id=${id}&innovation_group_id=3`,
      {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      }
    );
    // console.log("Cre", result);
    setcreative(result.data);
  };

  useEffect(() => {
    getinnovations();
    getcreative();
    getproduct();
  }, []);
  return (
    <div>
      <AppBar
        position="static"
        color="default"
        className={classes.appbar}
        style={{ borderRadius: "10px 10px 0 0", paddingTop: "2rem" }}
      >
        <Tabs
          value={valuePage1}
          onChange={handleChangePage1}
          variant="scrollable"
          scrollButtons="on"
          TabIndicatorProps={{
            style: { background: "#30acdc" },
          }}
          textColor="secondary"
          aria-label="scrollable tabs menu"
        >
          <Tab
            label="นวัตกรรม"
            className={classes.customLabel}
            {...a11yProps(0)}
          />

          <Tab
            label="ผลิตภัณฑ์"
            className={classes.customLabel}
            {...a11yProps(1)}
          />
          <Tab
            label="งานสร้างสรรค์"
            className={classes.customLabel}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>

      <div className="div-expertise">
        <TabPanel value={valuePage1} index={0} style={{ padding: "0 1rem" }}>
          {innovation.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                นวัตกรรมทั้งหมด {innovation.length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
              <Row>
                {innovation.map((listValue) => {
                  return (
                    <Col xs={12} sm={6} md={6} lg={4}>
                      <div
                        className="card card-bg-innovation card-innovation"
                        style={{
                          boxShadow:
                            "14px 13px 0px -5px rgb(159 159 159 / 37%)",
                          padding: "0.5rem",
                          backgroundColor: "#fff",
                        }}
                      >
                        <div className="card__image-holder">
                          <img
                            className="card__image img-innovation"
                            src={
                              listValue.innovation_image
                                ? listValue.innovation_image
                                : listValue.images.length
                                ? listValue.images[0].innovation_image_name
                                : no_img_innovation
                            }
                            onError={(e) =>
                              (e.target.onerror = null)(
                                (e.target.src = no_img_innovation)
                              )
                            }
                            alt="co_researcher_pi_image"
                          />
                        </div>
                        <div className="card-innovation-title">
                          <h2
                            className="innovation-title"
                            style={{ paddingTop: "0.5rem" }}
                          >
                            {listValue.innovation_name
                              ? listValue.innovation_name
                              : "-"}
                          </h2>
                          <small className="innovation">
                            ราคา:{" "}
                            {listValue.innovation_price
                              ? listValue.innovation_price
                              : "-"}{" "}
                            บาท
                          </small>
                        </div>
                        <div className="descriptions">
                          <h5 className="title-hover">
                            {" "}
                            {listValue.innovation_name
                              ? listValue.innovation_name
                              : "-"}
                          </h5>
                          <p>
                            <FcViewDetails size={15} /> :{" "}
                            {listValue.innovation_detail
                              ? listValue.innovation_detail.replace(
                                  /<[^>]+>|&nbsp;|&quot;/g,
                                  ""
                                )
                              : "-"}{" "}
                          </p>
                          <p>
                            จำนวนการผลิต :{" "}
                            {listValue.innovation_amount
                              ? listValue.innovation_amount
                              : "-"}{" "}
                            ชิ้น
                          </p>
                          <p>
                            <FcOnlineSupport size={15} /> :{" "}
                            {listValue.co_researcher_pi_coordinator
                              ? listValue.co_researcher_pi_coordinator
                              : "-"}
                          </p>
                          <p>
                            <FcPhone size={15} /> :{" "}
                            {listValue.co_researcher_pi_phone
                              ? listValue.co_researcher_pi_phone
                              : "-"}
                          </p>
                          <Link
                            to={`/InnovationDetail?type=innovation&innovationid=${listValue.innovation_id}&typetable=ustable`}
                            className="btn-innovation"
                          >
                            ดูเพิ่มเติม
                          </Link>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <div>ไม่พบข้อมูล</div>
          )}
        </TabPanel>
        <TabPanel value={valuePage1} index={1} style={{ padding: "0 1rem" }}>
          <div>
            {product.length ? (
              <>
                <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                  ผลิตภัณฑ์ทั้งหมด {product.length} รายการ
                </h5>
                <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
                <Row>
                  {product.map((listValue) => {
                    return (
                      <Col xs={12} sm={6} md={6} lg={4}>
                        <div
                          className="card card-bg-innovation card-innovation"
                          style={{
                            boxShadow:
                              "14px 13px 0px -5px rgb(159 159 159 / 37%)",
                            padding: "0.5rem",
                            backgroundColor: "#fff",
                          }}
                        >
                          {/* {listValue.images.length} */}
                          <div className="card__image-holder">
                            <img
                              className="card__image img-innovation"
                              src={
                                listValue.innovation_image
                                  ? listValue.innovation_image
                                  : listValue.images.length
                                  ? listValue.images[0]?.innovation_image_name
                                  : no_img_innovation
                              }
                              onError={(e) =>
                                (e.target.onerror = null)(
                                  (e.target.src = no_img_product)
                                )
                              }
                              alt="co_researcher_pi_image"
                            />
                          </div>
                          <div className="card-innovation-title">
                            <h2
                              className="innovation-title"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              {listValue.innovation_name
                                ? listValue.innovation_name
                                : "-"}
                            </h2>
                            <small className="innovation">
                              ราคา:{" "}
                              {listValue.innovation_price
                                ? listValue.innovation_price
                                : "-"}{" "}
                              บาท
                            </small>
                          </div>
                          <div className="descriptions">
                            <h5 className="title-hover">
                              {" "}
                              {listValue.innovation_name
                                ? listValue.innovation_name
                                : "-"}
                            </h5>
                            <p>
                              <FcViewDetails size={15} /> :{" "}
                              {listValue.innovation_detail
                                ? listValue.innovation_detail.replace(
                                    /<[^>]+>|&nbsp;|&quot;/g,
                                    ""
                                  )
                                : "-"}{" "}
                            </p>
                            <p>
                              จำนวนการผลิต :{" "}
                              {listValue.innovation_amount
                                ? listValue.innovation_amount
                                : "-"}{" "}
                              ชิ้น
                            </p>
                            <p>
                              <FcOnlineSupport size={15} /> :{" "}
                              {listValue.co_researcher_pi_coordinator
                                ? listValue.co_researcher_pi_coordinator
                                : "-"}
                            </p>
                            <p>
                              <FcPhone size={15} /> :{" "}
                              {listValue.co_researcher_pi_phone
                                ? listValue.co_researcher_pi_phone
                                : "-"}
                            </p>
                            <Link
                              to={`/InnovationDetail?type=product&innovationid=${listValue.innovation_id}&typetable=`}
                              className="btn-innovation"
                            >
                              ดูเพิ่มเติม
                            </Link>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ) : (
              <div>ไม่พบข้อมูล</div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={valuePage1} index={2} style={{ padding: "0 1rem" }}>
          <div>
            {cretive.length ? (
              <>
                <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                  งานสร้างสรรค์ทั้งหมด {cretive.length} รายการ
                </h5>
                <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
                <Row>
                  {cretive.map((listValue) => {
                    return (
                      <Col xs={12} sm={6} md={6} lg={4}>
                        <div
                          className="card card-bg-innovation card-innovation"
                          style={{
                            boxShadow:
                              "14px 13px 0px -5px rgb(159 159 159 / 37%)",
                            padding: "0.5rem",
                            backgroundColor: "#fff",
                          }}
                        >
                          <div className="card__image-holder">
                            <img
                              className="card__image img-innovation"
                              src={
                                listValue.innovation_image
                                  ? listValue.innovation_image
                                  : listValue.images[0].innovation_image_name
                                  ? listValue.images[0].innovation_image_name
                                  : no_img_innovation
                              }
                              onError={(e) =>
                                (e.target.onerror = null)(
                                  (e.target.src = no_img_creative)
                                )
                              }
                              alt="co_researcher_pi_image"
                            />
                          </div>
                          <div className="card-innovation-title">
                            <h2
                              className="innovation-title"
                              style={{ paddingTop: "0.5rem" }}
                            >
                              {listValue.innovation_name
                                ? listValue.innovation_name
                                : "-"}
                            </h2>
                            <small className="innovation">
                              ราคา:{" "}
                              {listValue.innovation_price
                                ? listValue.innovation_price
                                : "-"}{" "}
                              บาท
                            </small>
                          </div>
                          <div className="descriptions">
                            <h5 className="title-hover">
                              {" "}
                              {listValue.innovation_name
                                ? listValue.innovation_name
                                : "-"}
                            </h5>
                            <p>
                              <FcViewDetails size={15} /> :{" "}
                              {listValue.innovation_detail
                                ? listValue.innovation_detail.replace(
                                    /<[^>]+>|&nbsp;|&quot;/g,
                                    ""
                                  )
                                : "-"}{" "}
                            </p>
                            <p>
                              จำนวนการผลิต :{" "}
                              {listValue.innovation_amount
                                ? listValue.innovation_amount
                                : "-"}{" "}
                              ชิ้น
                            </p>
                            <p>
                              <FcOnlineSupport size={15} /> :{" "}
                              {listValue.co_researcher_pi_coordinator
                                ? listValue.co_researcher_pi_coordinator
                                : "-"}
                            </p>
                            <p>
                              <FcPhone size={15} /> :{" "}
                              {listValue.co_researcher_pi_phone
                                ? listValue.co_researcher_pi_phone
                                : "-"}
                            </p>
                            <Link
                              to={`/InnovationDetail?type=creative&innovationid=${listValue.innovation_id}&typetable=ustable`}
                              className="btn-innovation"
                            >
                              ดูเพิ่มเติม
                            </Link>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ) : (
              <div>ไม่พบข้อมูล</div>
            )}
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
