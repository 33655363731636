import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

import { Row, Col, Accordion } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import GoalPage from "../ConceptPage/GoalsDetail";
// import "../Css/researcher.scss";
import Lightbox from "../ImageLightbox/lightboxImage";
import { apiUrl } from "../../config/api.json";
import "./researcherstyle.scss";
import Impact from "./Impact";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-controls={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ fontFamily: "Prompt" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function Publication(props) {
  const { classes } = props;

  const [loading, setloading] = useState(true);
  const [books, setbooks] = useState([]);
  const [conference_national, setconference_national] = useState([]);
  const [conference_inter, setconference_inter] = useState([]);

  const [journal_national, setjournal_national] = useState([]);
  const [journal_inter, setjournal_inter] = useState([]);

  const [title, settitle] = useState("");
  const [type, settype] = useState("");
  const [published, setpublished] = useState("");
  const [publisher, setpublisher] = useState("");
  const [atpublished, setatpublished] = useState("");
  const [level, setlevel] = useState("");
  const [country, setcountry] = useState("");
  const [links, setlinks] = useState("");
  const [word, setword] = useState("");
  const [image, setimage] = useState("");
  const [images, setimages] = useState([]);
  const [video, setvideo] = useState("");
  const [goal, setgoal] = useState({});
  const [impact, setimpact] = useState({});
  const [valuePage1, setValuePage1] = useState(0);

  const div_detail_book = useRef();
  const div_detail_conference = useRef();
  const div_detail_journal = useRef();

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("user_idcard");
  };
  const idencrypt = getParamsId();

  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const YoutubeEmbed = ({ embedId }) => {
    const vdo_id = getId(embedId);
    return (
      <>
        {vdo_id !== null ? (
          <div
            className="video-responsive"
            style={{ width: "100%", height: 260 }}
          >
            <iframe
              src={`https://www.youtube.com/embed/${vdo_id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        ) : (
          <h5 style={{ marginTop: "3rem" }}>ลิงค์วิดีโอไม่ถูกต้อง</h5>
        )}
      </>
    );
  };
  YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired,
  };

  const getpublication = async () => {
    try {
      const result = await axios.get(
        `${apiUrl}/api/get/publication/${idencrypt}`
      );

      // if (Object.keys(result.data).length >= 3) {
      if (result.data.books.length) {
        setbooks(result.data.books);
        setgoal(result.data.books[0].goals);
        setimpact(result.data.books[0].impacts);

        settitle(result.data.books[0].book_name_th);
        settype("หนังสือ/คู่มือ");
        setpublisher(result.data.books[0].publisher);
        setpublished(
          result.data.books[0].book_published
            ? new Date(result.data.books[0].book_published).toLocaleDateString(
                "th-TH",
                {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                }
              )
            : null
        );
        setatpublished(result.data.books[0].time_publication);
        setlevel("-");
        setlinks(result.data.books[0].book_link);
        setcountry("-");
        setword(result.data.books[0].book_upload);
        setimage(result.data.books[0].book_image);
        setimages(result.data.books[0].images);
        setvideo("-");
      }

      if (result.data.conference.national.length) {
        setconference_national(result.data.conference.national);
      }
      if (result.data.conference.international.length) {
        setconference_inter(result.data.conference.international);
      }
      // console.log("jour", result.data.journal.national);
      if (result.data.journal.national.length) {
        setjournal_national(result.data.journal.national);
      }
      if (result.data.journal.international.length) {
        setjournal_inter(result.data.journal.international);
      }
      // }
      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };

  const DetailPage = (obj) => {
    let {
      typepub,
      title,
      type,
      published,
      bt_name_th,
      atpublished,
      publisher,
      level,
      // country,
      links,
      word,
      image,
      images,
      video,
    } = obj;
    // console.log("obj", obj);
    return (
      <div>
        <div>
          <Row>
            <Col md={6}>
              <p style={{ textAlign: "left" }}>
                <strong>
                  ชื่อ
                  {typepub === "book"
                    ? "หนังสือ : "
                    : typepub === "confer"
                    ? "ผลงาน :"
                    : typepub === "jour"
                    ? "ผลงาน :"
                    : ""}{" "}
                </strong>{" "}
                {title} <br />
                {typepub === "jour" ? (
                  <>
                    <strong>ชื่อวารสาร : </strong> {type} <br />
                  </>
                ) : typepub === "confer" ? (
                  <>
                    <strong>ชื่อประชุมวิชาการ : </strong> {type} <br />
                  </>
                ) : (
                  ""
                )}
                <strong>วันที่ตีพิมพ์: </strong> {published ? published : "-"}{" "}
                <br />
                {typepub === "book" ? (
                  <>
                    <strong>ประเภทหนังสือ : </strong> {bt_name_th} <br />{" "}
                    <strong>สำนักพิมพ์ : </strong> {publisher} <br />{" "}
                    <strong>ครั้งที่ตีพิมพ์ : </strong> {atpublished} ครั้ง{" "}
                    <br />{" "}
                  </>
                ) : (
                  ""
                )}
                {typepub === "jour" ? (
                  <>
                    <strong>ระดับคุณภาพ : </strong> {level}
                    <br />
                  </>
                ) : (
                  ""
                )}
                <strong>ลิงค์เว็บไซต์ : </strong>{" "}
                {links ? (
                  <a href={links} target="_blank" rel="noreferrer">
                    {links.split("/")[2]}{" "}
                  </a>
                ) : (
                  "-"
                )}
                <br />
                <>
                  <strong>เอกสาร : </strong>{" "}
                  {word !== "-" ? (
                    <a
                      href={`https://rmuts-rc.kims-rmuti.com/file-upload/publication-upload/${word}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {word}
                    </a>
                  ) : (
                    "-"
                  )}
                  <br />
                </>
                <br />
              </p>
            </Col>
            <Col md={6}>
              {image ? (
                <img
                  src={
                    image
                      ? image
                      : "https://teelindy.com/wp-content/uploads/2019/03/default_image.png"
                  }
                  width="100%"
                  alt="title_image"
                ></img>
              ) : (
                <div className="text-vertical-ceter">
                  <h6>ไม่มีรูปปก</h6>
                </div>
              )}
            </Col>
          </Row>
          <hr />
        </div>

        <div>
          <h5 style={{ textAlign: "left" }}>สื่อ : </h5>
          <Row>
            <Col md={6}>
              {image ? (
                <Lightbox image={image} images={images} position={0} />
              ) : (
                <div className="text-vertical-ceter">
                  <h6>ไม่มีรูปปก</h6>
                </div>
              )}
            </Col>
            <Col md={6}>
              {images.length >= 1 ? (
                <Lightbox
                  image={image}
                  images={images.length >= 1 ? images : [{ image: null }]}
                  position={1}
                />
              ) : (
                <div className="text-vertical-ceter">
                  <h6>ไม่มีรูปเพิ่มเติม</h6>
                </div>
              )}
            </Col>
            <Col md={12} style={{ paddingTop: "2rem" }}>
              <h5 style={{ textAlign: "left" }}>วิดีโอ : </h5>
              {video ? <YoutubeEmbed embedId={video} /> : <p>ไม่พบข้อมูล</p>}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const DetailPageGoal = (obj) => {
    let { sdgs, bcg, curve, cluster } = obj;
    return (
      <Col xl={12}>
        <br />
        <GoalPage sdgs={sdgs} bcg={bcg} curve={curve} cluster={cluster} />
      </Col>
    );
  };
  const DetailPageImpact = (obj) => {
    let { economy, society, culture, environment } = obj;
    return (
      <Col xl={12}>
        <br />
        <Impact
          economy={economy}
          society={society}
          culture={culture}
          environment={environment}
          loading_page="false"
        />
      </Col>
    );
  };

  const firstbook = books[0];
  const firstconnat = conference_national[0];
  const firstjournat = journal_national[0];

  useEffect(() => {
    getpublication();
  }, []);

  const handleChangePage1 = (event, newValue) => {
    setValuePage1(newValue);
    // console.log("value ", newValue);
    if (newValue === 0) {
      if (firstbook) {
        settitle(firstbook.book_name_th);
        settype("หนังสือ/คู่มือ");
        setpublisher(firstbook.publisher);
        setpublished(
          firstbook.book_published
            ? new Date(firstbook.book_published).toLocaleDateString("th-TH", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null
        );
        setatpublished(firstbook.time_publication);
        setlevel("-");
        setlinks(firstbook.book_link);
        setcountry("-");
        setword(firstbook.book_upload);
        setimage(firstbook.book_image);
        setimages(firstbook.images);
        setvideo("-");
        setgoal(firstbook.goals);
        setimpact(firstbook.impacts);
      }
    } else if (newValue === 1) {
      if (firstconnat) {
        settitle(firstconnat.conference_name_th);
        settype(firstconnat.conference_meeting_name_th);
        setpublished(
          firstconnat.conference_published
            ? new Date(firstconnat.conference_published).toLocaleDateString(
                "th-TH",
                {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                }
              )
            : null
        );
        setatpublished("-");
        setlevel("-");
        setlinks(firstconnat.conference_link);
        setcountry(firstconnat.ct_code);
        setword(firstconnat.conference_attachment);
        setimage(firstconnat.conference_image);
        setimages(firstconnat.images);
        setvideo(firstconnat.conference_vdo_link);
        setgoal(firstconnat.goals);
        setimpact(firstconnat.impacts);
      }
    } else if (newValue === 2) {
      if (firstjournat) {
        settitle(firstjournat.journal_name_th);
        settype(firstjournat.journal_abbreviation);
        setpublished(
          firstjournat.journal_published
            ? new Date(firstjournat.journal_published).toLocaleDateString(
                "th-TH",
                {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                }
              )
            : null
        );
        setatpublished("-");
        setlevel(firstjournat.level_name);
        setlinks(firstjournat.journal_link);
        setcountry(firstjournat.ct_code);
        setword(firstjournat.journal_attachment);
        setimage(firstjournat.journal_image);
        setimages(firstjournat.images);
        setvideo(firstjournat.journal_vdo_link);
        setgoal(firstjournat.goals);
        setimpact(firstjournat.impacts);
      }
    } else {
      settitle("");
      settype("");
      setpublished("");
      setatpublished("");
      setlevel("");
      setlinks("");
      setcountry("");
      setword("");
      setimage("");
      setimages([]);
      setvideo("");
      setgoal({});
      setimpact({});
    }
  };

  const clickDetail = (item) => {
    // console.log("item ", item);

    settitle(item.conference_name_th);
    settype(item.conference_meeting_name_th);
    setpublished(
      item.conference_published
        ? new Date(item.conference_published).toLocaleDateString("th-TH", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : null
    );
    setatpublished("-");
    setlevel("-");
    setlinks(item.conference_link);
    setcountry(item.ct_code);
    setword(item.conference_attachment);
    setimage(item.conference_image);
    setimages(item.images);
    setvideo(item.conference_vdo_link);
    setgoal(item.goals);
    setimpact(item.impacts);
    div_detail_conference.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const clickDetailBook = (item) => {
    // console.log("images book ", item);
    settitle(item.book_name_th);
    settype("หนังสือ/คู่มือ");
    setpublisher(item.publisher);
    setpublished(
      item.book_published
        ? new Date(item.book_published).toLocaleDateString("th-TH", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : null
    );
    setatpublished(item.time_publication);
    setlevel("-");
    setlinks(item.book_link);
    setcountry("-");
    setword(item.book_upload);
    setimage(item.book_image);
    setimages(item.images);
    setvideo("-");
    setgoal(item.goals);
    setimpact(item.impacts);
    div_detail_book.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const clickDetailJournal = (item) => {
    settitle(item.journal_name_th);
    settype(item.journal_abbreviation);
    setpublished(
      item.journal_published
        ? new Date(item.journal_published).toLocaleDateString("th-TH", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : null
    );
    setatpublished("-");
    setlevel(item.level_name);
    setlinks(item.journal_link);
    setcountry(item.ct_code);
    setword(item.journal_attachment);
    setimage(item.journal_image);
    setimages(item.images);
    setvideo(item.journal_vdo_link);
    setgoal(item.goals);
    setimpact(item.impacts);
    div_detail_journal.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  // console.log("books :", books);
  // console.log("economy :", impact);

  return (
    <>
      {loading ? (
        <div className="demo"></div>
      ) : (
        <>
          <AppBar
            position="static"
            color="default"
            className={classes.appbar}
            style={{ borderRadius: "10px 10px 0 0", paddingTop: "2rem" }}
          >
            <Tabs
              value={valuePage1}
              onChange={handleChangePage1}
              variant="scrollable"
              scrollButtons="on"
              TabIndicatorProps={{
                style: { background: "#30acdc" },
              }}
              textColor="secondary"
              aria-label="scrollable tabs menu"
            >
              <Tab
                label="หนังสือ/คู่มือ"
                className={classes.customLabel}
                {...a11yProps(0)}
              />

              <Tab
                label="ประชุมวิชาการ"
                className={classes.customLabel}
                {...a11yProps(1)}
              />
              <Tab
                label="วารสาร"
                className={classes.customLabel}
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>
          <div className="div-expertise">
            {/* หนังสือ books */}
            <TabPanel
              value={valuePage1}
              index={0}
              className="re-expertise-detail"
            >
              <div style={{ marginRight: "-25px" }}>
                <Row>
                  <Col xl={4}>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        border: "1px solid #fff",
                        padding: 20,
                      }}
                    >
                      <h6 style={{ textAlign: "left" }}>
                        <b>ทั้งหมด {books.length} ฉบับ </b>
                        <hr />
                      </h6>
                      <div className="timeline-book">
                        <div className="outer">
                          {books.length ? (
                            books.map((item, index) => (
                              <div
                                key={index}
                                className={`cardtime ${
                                  item.book_name_th === title ? "active" : ""
                                }`}
                                tabindex="1"
                                onClick={() => clickDetailBook(item)}
                              >
                                <div className="info">
                                  <p
                                    className="title"
                                    style={{ textAlign: "left" }}
                                  >
                                    <strong>ชื่อหนังสือ/คู่มือ: </strong>
                                    {item.book_name_th} <br />
                                  </p>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div>ไม่พบข้อมูล</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={8} ref={div_detail_book}>
                    <div className="detail_publication">
                      {firstbook?.book_name_th ? (
                        <DetailPage
                          typepub="book"
                          title={title}
                          type={type !== "" ? type : "หนังสือ/คู่มือ"}
                          publisher={publisher}
                          published={published}
                          atpublished={atpublished}
                          level={level !== "" ? level : "-"}
                          country={country !== "" ? country : "-"}
                          links={links}
                          word={word !== "" ? word : "-"}
                          image={image}
                          images={images.length !== 0 ? images : []}
                          video={video !== "" ? video : ""}
                        />
                      ) : (
                        <div>ไม่พบข้อมูล</div>
                      )}
                    </div>
                  </Col>
                  {firstbook?.book_name_th ? (
                    <>
                      <Col xl={12}>
                        <br />
                        <DetailPageGoal
                          sdgs={goal.sdgs ? goal.sdgs : []}
                          bcg={goal.bcg ? goal.bcg : []}
                          curve={goal.curve ? goal.curve : []}
                          cluster={goal.cluster ? goal.cluster : []}
                        />
                      </Col>
                      <Col xl={12}>
                        <DetailPageImpact
                          economy={impact.economy ? impact.economy : []}
                          society={impact.social ? impact.social : []}
                          culture={impact.culture ? impact.culture : []}
                          environment={
                            impact.environment ? impact.environment : []
                          }
                        />
                      </Col>
                    </>
                  ) : null}
                </Row>
              </div>
            </TabPanel>

            {/* ประชุมวิชาการ  conference */}
            <TabPanel
              value={valuePage1}
              index={1}
              className="re-expertise-detail"
            >
              <div style={{ marginRight: "-25px" }}>
                <Row>
                  <Col xl={4}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item
                        eventKey="0"
                        style={{
                          backgroundColor: "#fff",
                          border: "none",
                        }}
                      >
                        <Accordion.Header>
                          <h6
                            style={{
                              textAlign: "left",
                              color: "#212529",
                              marginBottom: "1rem",
                            }}
                          >
                            <b>ระดับชาติ</b>
                          </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h6 style={{ textAlign: "left" }}>
                            <b>ทั้งหมด {conference_national.length} ฉบับ </b>
                            <hr style={{ height: "0.1px" }} />
                          </h6>
                          <div className="timeline">
                            <div className="outer">
                              {conference_national.length ? (
                                conference_national.map((item) => (
                                  <div
                                    className={`cardtime ${
                                      item.conference_name_th === title
                                        ? "active"
                                        : ""
                                    }`}
                                    tabindex="1"
                                    onClick={() => clickDetail(item)}
                                  >
                                    <div className="info">
                                      <p
                                        className="title"
                                        style={{
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <strong>ชื่องานประชุมวิชาการ: </strong>
                                        {item.conference_name_th} <br />
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div>ไม่พบข้อมูล</div>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion
                      style={{ marginTop: "1rem" }}
                      defaultActiveKey="0"
                    >
                      <Accordion.Item
                        eventKey="0"
                        style={{
                          backgroundColor: "#fff",
                          border: "none",
                        }}
                      >
                        <Accordion.Header>
                          <h6
                            style={{
                              textAlign: "left",
                              color: "#212529",
                              marginBottom: "1rem",
                            }}
                          >
                            <b>ระดับนานาชาติ</b>
                          </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h6 style={{ textAlign: "left" }}>
                            <b>ทั้งหมด {conference_inter.length} ฉบับ </b>
                            <hr style={{ height: "0.1px" }} />
                          </h6>
                          <div className="timeline">
                            <div className="outer">
                              {conference_inter.length ? (
                                conference_inter.map((item) => (
                                  <div
                                    className={`cardtime ${
                                      item.conference_name_th === title
                                        ? "active"
                                        : ""
                                    }`}
                                    tabindex="1"
                                    onClick={() => clickDetail(item)}
                                  >
                                    <div className="info">
                                      <p
                                        className="title"
                                        style={{ textAlign: "left" }}
                                      >
                                        <strong>ชื่องานประชุมวิชาการ: </strong>
                                        {item.conference_name_th} <br />
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div>ไม่พบข้อมูล</div>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>

                  <Col xl={8} ref={div_detail_conference}>
                    <div className="detail_publication">
                      {firstconnat?.conference_name_th ? (
                        <DetailPage
                          typepub="confer"
                          title={title}
                          type={type}
                          published={published}
                          atpublished={atpublished !== "" ? atpublished : "-"}
                          level={level !== "" ? level : "-"}
                          country={country}
                          links={links}
                          word={word}
                          image={image}
                          images={images}
                          video={video}
                        />
                      ) : (
                        <div>ไม่พบข้อมูล</div>
                      )}
                    </div>
                  </Col>
                  {firstconnat?.conference_name_th ? (
                    <>
                      <Col xl={12}>
                        <br />
                        <DetailPageGoal
                          sdgs={goal.sdgs ? goal.sdgs : []}
                          bcg={goal.bcg ? goal.bcg : []}
                          curve={goal.curve ? goal.curve : []}
                          cluster={goal.cluster ? goal.cluster : []}
                        />
                      </Col>
                      <Col xl={12}>
                        <DetailPageImpact
                          economy={impact.economy ? impact.economy : []}
                          society={impact.social ? impact.social : []}
                          culture={impact.culture ? impact.culture : []}
                          environment={
                            impact.environment ? impact.environment : []
                          }
                        />
                      </Col>
                    </>
                  ) : null}
                </Row>
              </div>
            </TabPanel>
            {/* วารสาร  journal*/}
            <TabPanel
              value={valuePage1}
              index={2}
              className="re-expertise-detail"
            >
              <div style={{ marginRight: "-25px" }}>
                <Row>
                  <Col xl={4}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item
                        eventKey="0"
                        style={{
                          backgroundColor: "#fff",
                          border: "none",
                        }}
                      >
                        <Accordion.Header>
                          <h6
                            style={{
                              textAlign: "left",
                              color: "#212529",
                              marginBottom: "1rem",
                            }}
                          >
                            <b>ระดับชาติ</b>
                          </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h6 style={{ textAlign: "left" }}>
                            <b>ทั้งหมด {journal_national.length} ฉบับ </b>
                            <hr style={{ height: "0.1px" }} />
                          </h6>
                          <div className="timeline">
                            <div className="outer">
                              {journal_national.length ? (
                                journal_national.map((item) => (
                                  <div
                                    className={`cardtime ${
                                      item.journal_name_th === title
                                        ? "active"
                                        : ""
                                    }`}
                                    tabindex="1"
                                    onClick={() => clickDetailJournal(item)}
                                  >
                                    <div className="info">
                                      <p
                                        className="title"
                                        style={{ textAlign: "left" }}
                                      >
                                        <strong>ชื่องานวารสาร: </strong>
                                        {item.journal_name_th} <br />
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div>ไม่พบข้อมูล</div>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion
                      style={{ marginTop: "1rem" }}
                      defaultActiveKey="0"
                    >
                      <Accordion.Item
                        eventKey="0"
                        style={{
                          backgroundColor: "#fff",
                          border: "none",
                        }}
                      >
                        <Accordion.Header>
                          <h6
                            style={{
                              textAlign: "left",
                              color: "#212529",
                              marginBottom: "1rem",
                            }}
                          >
                            <b>ระดับนานาชาติ</b>
                          </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h6 style={{ textAlign: "left" }}>
                            <b>ทั้งหมด {journal_inter.length} ฉบับ </b>
                            <hr style={{ height: "0.1px" }} />
                          </h6>

                          <div className="timeline">
                            <div className="outer">
                              {journal_inter.length ? (
                                journal_inter.map((item) => (
                                  <div
                                    className={`cardtime ${
                                      item.journal_name_th === title
                                        ? "active"
                                        : ""
                                    }`}
                                    tabindex="1"
                                    onClick={() => clickDetailJournal(item)}
                                  >
                                    <div className="info">
                                      <p
                                        className="title"
                                        style={{ textAlign: "left" }}
                                      >
                                        <strong>ชื่องานวารสาร: </strong>
                                        {item.journal_name_th} <br />
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div>ไม่พบข้อมูล</div>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>

                  <Col xl={8} ref={div_detail_journal}>
                    <div className="detail_publication">
                      {firstjournat?.journal_name_th ? (
                        <DetailPage
                          typepub="jour"
                          title={title}
                          type={type}
                          published={published}
                          atpublished={atpublished !== "" ? atpublished : "-"}
                          level={level}
                          country={country}
                          links={links}
                          word={word !== "" ? word : "-"}
                          image={image}
                          images={images}
                          video={video}
                        />
                      ) : (
                        "ไม่พบข้อมูล"
                      )}
                    </div>
                  </Col>

                  {firstjournat?.journal_name_th ? (
                    <>
                      <Col xl={12}>
                        <br />
                        <DetailPageGoal
                          sdgs={goal.sdgs ? goal.sdgs : []}
                          bcg={goal.bcg ? goal.bcg : []}
                          curve={goal.curve ? goal.curve : []}
                          cluster={goal.cluster ? goal.cluster : []}
                        />
                      </Col>
                      <Col xl={12}>
                        <DetailPageImpact
                          economy={impact.economy ? impact.economy : []}
                          society={impact.social ? impact.social : []}
                          culture={impact.culture ? impact.culture : []}
                          environment={
                            impact.environment ? impact.environment : []
                          }
                        />
                      </Col>
                    </>
                  ) : null}
                </Row>
              </div>
            </TabPanel>
          </div>
        </>
      )}
    </>
  );
}
