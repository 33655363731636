import { Row, Col } from "reactstrap";
import React, { useState } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/dist/css/splide.min.css";
import QRCode from "qrcode.react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// import "./Css/Search.scss";
// import "../index.css";
import { MdEmail } from "react-icons/md";
import { FaLink, FaFacebookSquare, FaLine } from "react-icons/fa";

import { FcManager } from "react-icons/fc";

import { withRouter } from "react-router";
import axios from "axios";

import { useTranslation } from "react-i18next";

// import no_img_innovat from "../../images/no_img_patent.png";
// import no_img_product from "../../images/no_img_product.png";
// import no_img_creative from "../../images/no_img_creative.png";

import no_img_research from "../../images/no_img_research.png";
import no_img_co_research from "../../images/no_img_co_research.png";
import no_img_network from "../../images/no_img_network.png";

import "../Css/innovation.scss";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config/api.json";

function InnovationDetail(props) {
  const [innovationlist, setinnovationlist] = useState([]);
  const [innovation, setinnovation] = useState({});
  const [innovation_image, setinnovation_image] = useState([]);
  const [innovation_video, setinnovation_video] = useState("");
  const { t } = useTranslation();

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("patent_name");
  };

  const getParamsType = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("type");
  };
  const innovation_id = getParamsId();
  const type = getParamsType();
  // const typetable = getParamsTypeTable();

  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  var url_all;
  var url_find;
  var title_more;
  var no_img_patent;

  if (type === "researcher") {
    url_all = `${apiUrl}/api/get/patent?patent_type_id=&title=`;
    url_find = `${apiUrl}/api/get/patent?patent_type_id=&title=${innovation_id}`;
    title_more = "innovation.title_more_innovation";
    no_img_patent = no_img_research;
  } else if (type === "community") {
    url_all = `${apiUrl}/api/get/co-researcher/commupatent/list?title=`;
    url_find = `${apiUrl}/api/get/co-researcher/commupatent/list?title=${innovation_id}`;
    title_more = "innovation.title_more_product";
    no_img_patent = no_img_co_research;
  } else if (type === "conetwork") {
    url_all = `${apiUrl}/api/get/co-researcher/cooppatent/list?title=`;
    url_find = `${apiUrl}/api/get/co-researcher/cooppatent/list?title=${innovation_id}`;
    title_more = "innovation.title_more_creative";
    no_img_patent = no_img_network;
  }
  const getinnovationlist = () => {
    axios
      .get(url_all)
      .then((res) => {
        setinnovationlist(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getinnovation = () => {
    axios
      .get(url_find)
      .then((res) => {
        setinnovation(res.data[0]);
        if (res.data[0].images) {
          setinnovation_image(res.data[0].images);
        }
        if (res.data[0].patent_vdio) {
          setinnovation_video(res.data[0].patent_vdio);
        } else {
          setinnovation_video(res.data[0].co_researcher_vdo);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    setinnovationlist([]);
    setinnovation({});
    setinnovation_image([]);
    setinnovation_video("");

    window.scrollTo(0, 0);
    getinnovationlist();
    getinnovation();
  }, [innovation_id]);

  let innovationdata = innovationlist.map(function (listValue, i) {
    var url_image = "";
    if (listValue.patent_images) {
      url_image =
        `https://rmuts-rc.kims-rmuti.com/file-upload/patent-upload/` +
        listValue.patent_images;
    } else if (listValue.co_patent_image) {
      url_image =
        `https://rmuts-rc.kims-rmuti.com/file-upload/co_patent-upload/` +
        listValue.co_patent_image;
    } else if (listValue.images && listValue.images.length > 0) {
      if (listValue.images[0].co_patent_image) {
        url_image =
          `https://rmuts-rc.kims-rmuti.com/file-upload/co_patent-upload/` +
          listValue.images[0].co_patent_image;
      }
    } else {
      url_image = no_img_patent;
    }

    var url_detail;

    if (type === "researcher") {
      url_detail = `/PatentDetail?type=researcher&patent_name=${listValue.patent_name_th}`;
    } else if (type === "community") {
      url_detail = `/PatentDetail?type=community&patent_name=${listValue.patent_name_th}`;
    } else if (type === "conetwork") {
      url_detail = `/PatentDetail?type=conetwork&patent_name=${listValue.co_patent_name_th}`;
    }

    return (
      <SplideSlide key={i}>
        <div className="card card-bg-innovation card-innovation">
          <div className="card__image-holder">
            <img
              className="card__image img-innovation"
              src={url_image}
              onError={(e) =>
                (e.target.onerror = null)((e.target.src = no_img_research))
              }
              alt="co_innovation_image"
            />
          </div>
          <div className="card-innovation-title">
            <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
              {listValue.patent_name_th
                ? listValue.patent_name_th
                : listValue.co_patent_name_th}
            </h2>
            <small className="innovation">
              <FcManager size={15} /> :{" "}
              {listValue.patent_application
                ? listValue.patent_application
                : `${listValue.coordinator_prefix}${listValue.coordinator_name_th} ${listValue.coordinator_lastname_th}`}
            </small>
          </div>
          <div className="descriptions">
            <h5 className="title-hover">
              {listValue.patent_name_th
                ? listValue.patent_name_th
                : listValue.co_patent_name_th}
            </h5>
            <p>
              {t("patent.registration_date")} :
              {listValue.patent_date
                ? new Date(listValue.patent_date).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : new Date(listValue.co_patent_date).toLocaleDateString(
                    "th-TH",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
            </p>

            <p>
              {t("patent.request_number")} :
              {listValue.patent_request
                ? listValue.patent_request
                : listValue.co_patent_request
                ? listValue.co_patent_request
                : "-"}
            </p>
            <p>
              <p>
                {t("patent.registration_applicant")} :{" "}
                {listValue.patent_application
                  ? listValue.patent_application
                  : `${listValue.coordinator_prefix}${listValue.coordinator_name_th} ${listValue.coordinator_lastname_th}`}
              </p>
            </p>
            <Link to={url_detail} className="btn-innovation">
              {t("innovation.seemore")}
            </Link>
          </div>
        </div>
      </SplideSlide>
    );
  });

  const options = (count) => {
    return {
      type: count <= 4 ? "slide" : "loop",
      gap: "1rem",
      perPage: 5,
      perMove: 1,
      interval: 2500,
      pagination: false,
      speed: 2000,
      breakpoints: {
        2560: {
          perPage: 5,
        },
        1440: {
          perPage: 4,
        },
        1024: {
          perPage: 4,
        },
        768: {
          perPage: 3,
        },
        640: {
          perPage: 2,
        },
      },
      autoplay: true,
      pauseOnHover: true,
      resetProgress: false,
    };
  };

  const images = [];

  if (innovation.patent_images) {
    images.push({
      original:
        `https://rmuts-rc.kims-rmuti.com/file-upload/patent-upload/` +
        innovation.patent_images,
      thumbnail:
        `https://rmuts-rc.kims-rmuti.com/file-upload/patent-upload/` +
        innovation.patent_images,
    });
  }

  innovation_image.forEach(function (data) {
    if (data.co_patent_image) {
      images.push({
        original:
          `https://rmuts-rc.kims-rmuti.com/file-upload/co_patent-upload/` +
          data.co_patent_image,
        thumbnail:
          `https://rmuts-rc.kims-rmuti.com/file-upload/co_patent-upload/` +
          data.co_patent_image,
      });
    }
  });

  // console.log("innovation  ", innovation);
  return (
    <div className="body-detail">
      <div className="body-innovation">
        <Row>
          <Col sm={12}>
            <div className="inno-title">
              <h5
                style={{
                  color: "#000",
                  fontFamily: "Prompt",
                  textAlign: "left",
                }}
              >
                {innovation.patent_name_th
                  ? innovation.patent_name_th
                  : innovation.co_patent_name_th}
              </h5>
            </div>
          </Col>
          <Col sm={12} md={6} style={{ padding: "15px 10px 10px 30px" }}>
            {images.length > 1 ? (
              <div className="inno-image">
                <ImageGallery showThumbnails={true} items={images} />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="inno-image"
              >
                {innovation.patent_images ? (
                  <img
                    className="img-radius"
                    src={`https://rmuts-rc.kims-rmuti.com/file-upload/patent-upload/${innovation.patent_images}`}
                    onError={(e) =>
                      (e.target.onerror = null)((e.target.src = no_img_patent))
                    }
                    style={{ width: "100%" }}
                    alt="patent-img"
                  />
                ) : (
                  <div className="title-center">
                    <Row>
                      <Col md={12}>
                        <h3 style={{ color: "#000", fontFamily: "Prompt" }}>
                          {t("notimage")}
                        </h3>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </Col>
          <Col sm={12} md={6}>
            <div className="inno-video">
              <h4>{t("patent.video")}</h4>
              {innovation_video ? (
                <>
                  {getId(innovation_video) !== null ? (
                    <iframe
                      style={{ width: "100%", height: "90%" }}
                      src={`https://www.youtube.com/embed/${getId(
                        innovation_video
                      )}`}
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : (
                    <div className="title-center">
                      <h3 style={{ color: "#000", fontFamily: "Prompt" }}>
                        ลิงค์วิดีโอไม่ถูกต้อง
                      </h3>
                    </div>
                  )}
                </>
              ) : (
                <div className="title-center">
                  <Row>
                    <Col md={12}>
                      <h3 style={{ color: "#000", fontFamily: "Prompt" }}>
                        {t("notvideo")}
                      </h3>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <div className="inno-contact">
              <h6>
                {t("patent.type")} :{" "}
                {innovation.patent_type_name
                  ? innovation.patent_type_name
                  : null}
              </h6>
              <h6>
                {t("patent.detail")} :{" "}
                {innovation.patent_name_th
                  ? innovation.patent_name_th
                  : innovation.co_researcher_history}
              </h6>
              <h6>
                {t("patent.patent_number")} :{" "}
                {innovation.patent_number ? innovation.patent_number : " -"}
              </h6>
              <h6>
                {t("patent.registration_applicant")} :{" "}
                {innovation.patent_application
                  ? innovation.patent_application
                  : innovation.co_patent_id
                  ? `${innovation.coordinator_prefix} ${innovation.coordinator_name_th} ${innovation.coordinator_lastname_th}`
                  : "-"}
              </h6>
              <h6>
                {t("patent.tel")} :{" "}
                {innovation.user_mobile
                  ? innovation.user_mobile
                  : innovation.co_researcher_phone
                  ? innovation.co_researcher_phone
                  : "-"}
              </h6>
              <h6>
                {t("patent.file")} :{" "}
                {innovation.patent_attachment ? (
                  <a
                    href={`https://rmuts-rc.kims-rmuti.com/file-upload/patent-upload/${innovation.patent_attachment}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {innovation.patent_attachment}
                  </a>
                ) : (
                  "-"
                )}
              </h6>

              <Row style={{ paddingTop: "2rem" }}>
                <Col md={3} style={{ textAlign: "center" }}>
                  <FaLink
                    size={40}
                    color={"#ae8753"}
                    className="icon-socail"
                    align="center"
                  />

                  <div>
                    <p className="card-title" align="center">
                      &nbsp;
                      {innovation.patene_link ? (
                        <div>
                          <a
                            href={innovation.patene_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            <QRCode
                              value={`${innovation.patene_link}`}
                              style={{ marginRight: 0 }}
                              size={100}
                            />
                          </a>

                          <p>LINK</p>
                        </div>
                      ) : innovation.co_researcher_website ? (
                        <div>
                          <a
                            href={innovation.co_researcher_website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            <QRCode
                              value={`${innovation.co_researcher_website}`}
                              style={{ marginRight: 0 }}
                              size={100}
                            />
                          </a>

                          <p>LINK</p>
                        </div>
                      ) : (
                        <p>ไม่พบลิงก์</p>
                      )}
                      &nbsp;
                    </p>
                  </div>
                </Col>

                <Col md={3} style={{ textAlign: "center" }}>
                  <FaFacebookSquare
                    size={40}
                    color={"#1877F2"}
                    className="icon-socail"
                    align="center"
                  />
                  <div>
                    <p className="card-title" align="center">
                      &nbsp;
                      {innovation.co_researcher_pi_facebook ? (
                        <div>
                          <a
                            href={innovation.co_researcher_pi_facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <QRCode
                              value={`${innovation.co_researcher_pi_facebook}`}
                              style={{ marginRight: 0 }}
                              size={100}
                            />
                          </a>

                          <p>FACEBOOK</p>
                        </div>
                      ) : innovation.user_facebook ? (
                        <div>
                          <a
                            href={innovation.user_facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <QRCode
                              value={`${innovation.user_facebook}`}
                              style={{ marginRight: 0 }}
                              size={100}
                            />
                          </a>

                          <p>FACEBOOK</p>
                        </div>
                      ) : (
                        <p>ไม่พบบัญชี</p>
                      )}
                      &nbsp;
                    </p>
                  </div>
                </Col>

                <Col md={3} style={{ textAlign: "center" }}>
                  <FaLine
                    size={40}
                    color={"#0ec863"}
                    className="icon-socail"
                    align="center"
                  />

                  <div>
                    <p className="card-title" align="center">
                      &nbsp;
                      {innovation.co_researcher_line ? (
                        <div>
                          <a
                            href={`https://line.me/ti/p/~${innovation.co_researcher_line}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <QRCode
                              value={`https://line.me/ti/p/~${innovation.co_researcher_line}`}
                              style={{ marginRight: 0 }}
                              size={100}
                            />
                          </a>

                          <p>LINE</p>
                        </div>
                      ) : innovation.user_line ? (
                        <div>
                          <a
                            href={`https://line.me/ti/p/~${innovation.user_line}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <QRCode
                              value={`https://line.me/ti/p/~${innovation.user_line}`}
                              style={{ marginRight: 0 }}
                              size={100}
                            />
                          </a>

                          <p>LINE</p>
                        </div>
                      ) : (
                        <p>ไม่พบบัญชี</p>
                      )}
                      &nbsp;
                    </p>
                  </div>
                </Col>

                <Col md={3} style={{ textAlign: "center" }}>
                  <MdEmail
                    size={40}
                    color={"#ea4335"}
                    className="icon-socail"
                    align="center"
                  />

                  <div>
                    <p className="card-title" align="center">
                      &nbsp;
                      {innovation.co_researcher_mail ? (
                        <div>
                          <a
                            href={`mailto:${innovation.co_researcher_mail}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <QRCode
                              value={`mailto:${innovation.co_researcher_mail}`}
                              style={{ marginRight: 0 }}
                              size={100}
                            />
                          </a>

                          <p>E-Mail</p>
                        </div>
                      ) : innovation.user_mail ? (
                        <div>
                          <a
                            href={`mailto:${innovation.user_mail}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <QRCode
                              value={`mailto:${innovation.user_mail}}`}
                              style={{ marginRight: 0 }}
                              size={100}
                            />
                          </a>

                          <p>E-Mail</p>
                        </div>
                      ) : (
                        <p>ไม่พบ E-Mail</p>
                      )}
                      &nbsp;
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className="inno-other">
          <Col sm={12}>
            <h3
              style={{ color: "#000", fontFamily: "Prompt", textAlign: "left" }}
            >
              {t("patent.related_intellectual")}
            </h3>
          </Col>
          <Col md={12} style={{ textAlign: "right" }}>
            <Link
              className="custom-btn btn-14"
              style={{
                color: "white",
                fontFamily: "Prompt",
                cursor: "pointer",
                textDecoration: "none",
                textAlign: "center",
                width: "max-content",
              }}
              to={`/PatentAll?type=${type}`}
            >
              <span style={{ fontSize: "16px" }}>
                {t("innovation.viewall")} ({innovationlist.length})
              </span>
            </Link>
          </Col>
          <Col sm={12} style={{ marginRight: "1rem" }}>
            {innovationlist.length > 0 ? (
              <Splide
                options={options(innovationlist.length)}
                aria-labelledby="autoplay-example-heading"
              >
                {innovationdata}
              </Splide>
            ) : null}
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default withRouter(InnovationDetail);
