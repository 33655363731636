import { Row, Col } from "reactstrap";
import React from "react";
import logo_footer from "../images/logo_footer.png";
import footer_stiker from "../images/footer_stiker.png";
import baitlogo from "../images/baitlogo.png";

export default function Footer() {
  return (
    <div className="footer-detail">
      <div className="footer-bg">
        <Row style={{ width: "100%", padding: "2rem", paddingBottom: "1rem" }}>
          <Col xs={6} xl={2}>
            <Row>
              <Col sm={12} style={{ textAlign: "left" }}>
                <h6>Made by</h6>
              </Col>
              <Col sm={12}>
                <img
                  src={logo_footer}
                  style={{ width: "80%", maxWidth: "150px" }}
                  alt="logo_footer"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6} xl={2}>
            <Row>
              {/* ///logo คณะบริหาร// */}
              <Col sm={12} style={{ textAlign: "left" }}>
                <h6>Sponsorship</h6>
              </Col>
              <Col xs={6} sm={6}>
                <img
                  src={baitlogo}
                  style={{ width: "200%", maxWidth: "200px" }}
                  alt="baitlogo"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={7}>
            <Row>
              <Col sm={12} style={{ textAlign: "left" }}>
                <h6>Address: </h6>
              </Col>
              <Col
                sm={12}
                style={{
                  textAlign: "left",
                  marginBottom: "1.2rem",
                  marginTop: "0.2rem",
                  paddingRight: "2rem",
                }}
              >
                <h6>
                  Faculty of Business Administration and Information Technology
                  , Rajamangala University of Technology Suvarnabhumi 19 Moo 3
                  Tha Wasukri Subdistrict , Phra Nakhon Si Ayutthaya District ,
                  Phra Nakhon Si Ayutthaya Province 13000
                </h6>
              </Col>
              <Col sm={12} style={{ textAlign: "left", bottom: "0" }}>
                <h6>&copy; Copyright 2021 KIL. All Rights Reserved</h6>
              </Col>
            </Row>
          </Col>
          <Col
            xs={6}
            xl={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={footer_stiker}
              style={{ width: "150%", maxWidth: "150px" }}
              alt="footer_stiker"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
