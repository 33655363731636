import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";
import { FaChevronCircleDown } from "react-icons/fa";

import no_img_product from "../../images/no_img_product.png";
import { t } from "i18next";
import Youtubeshow from "../Detail/Youtubeshow";
import Imageshow from "../Detail/Imageshow";
import { apiUrl } from "../../config/api.json";
export default function KnowledgePage(props) {
  const { co_researcher_id } = props;
  const [knowledge, setknowledge] = useState([]);
  const [knowledgeDetail, setknowledgeDetail] = useState([]);

  const [knowledge_district, setknowledge_district] = useState([]);
  const [knowledge_community, setknowledge_community] = useState([]);
  const [knowledge_phi, setknowledge_phi] = useState([]);

  const divdetailKn = useRef();

  let id = atob(co_researcher_id);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/get/co-researcher-knowledge/image/${id}`)
      .then((result) => {
        if (result.data.knowledges) {
          setknowledge(result.data.knowledges);
        }

        if (result.data.details) {
          result.data.details.forEach((el) => {
            if (el.co_group_name === "ตำบล") {
              if (el.knowledges.length) {
                setknowledge_district(el);
              }
            } else if (el.co_group_name === "ชุมชน") {
              if (el.knowledges.length) {
                setknowledge_community(el);
              }
            } else if (el.co_group_name === "ปราชญ์") {
              if (el.knowledges.length) {
                setknowledge_phi(el);
              }
            }
          });
        }

        // console.log("data ", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onClickKn = (data) => {
    setknowledgeDetail([data]);
    divdetailKn.current.scrollIntoView({ behavior: "smooth" });
  };

  const knowledgediv = knowledgeDetail.map((data, index) => {
    const imagesKn = [];
    // console.log("data", data);
    // console.log("data :", typeof data.images);
    if (typeof data.images === "object") {
      data.images.forEach(function (listitem) {
        if (listitem.co_kl_image) {
          imagesKn.push({
            original: `https://rmuts-rc.kims-rmuti.com/file-upload/co-knowledge-upload/${listitem.co_kl_image}`,
            thumbnail: `https://rmuts-rc.kims-rmuti.com/file-upload/co-knowledge-upload/${listitem.co_kl_image}`,
          });
        }
      });
    }

    return (
      <div
        className="blog-card newkn-div"
        key={index}
        style={{ margin: "2rem -1rem -0.4rem -1rem" }}
      >
        <Row
          key={index}
          className="body-expertise"
          style={{ backgroundColor: "#fff" }}
        >
          <Col sm={12} style={{ marginBottom: "1rem" }}>
            <h3 style={{ textAlign: "left" }}>
              {data.co_researcher_knowledge_name}
            </h3>
            <hr />
          </Col>

          <Imageshow
            image={`https://rmuts-rc.kims-rmuti.com/file-upload/co-knowledge-upload/${data.co_researcher_knowledge_image}`}
            images={data.images}
            imageshow={imagesKn}
            lg={6}
          />

          <Youtubeshow
            url_video={
              data.co_researcher_knowledge_videolink
                ? data.co_researcher_knowledge_videolink
                : ""
            }
            xs={12}
            sm={12}
            md={12}
            lg={6}
            paddingTop={"0"}
            className={""}
          />

          <Col xs={12} sm={12} style={{ paddingTop: "2rem" }}>
            <hr />
            <div className="newkn-detail">
              <p>
                <b>{t("community.expertise.detail")} : </b>{" "}
                {data.co_researcher_knowledge_detail}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  const DetailPage = (obj) => {
    let { dataKn } = obj;
    // console.log("obj", obj);
    return (
      <>
        {dataKn ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {dataKn.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />

            {dataKn.knowledges?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  {ex.knowledges.map((data, index) => (
                    <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                      <div
                        className="card-output"
                        style={{ backgroundColor: "#fff" }}
                        onClick={() => onClickKn(data)}
                      >
                        <img
                          className="img-output"
                          src={
                            data.co_researcher_knowledge_image
                              ? `https://rmuts-rc.kims-rmuti.com/file-upload/co-knowledge-upload/${data.co_researcher_knowledge_image}`
                              : no_img_product
                          }
                          onError={(e) =>
                            (e.target.onerror = null)(
                              (e.target.src = no_img_product)
                            )
                          }
                          alt="output_image"
                        />
                        <div className="output-descreption">
                          <Row>
                            <Col xs={10}>
                              <h6>
                                <b>{data.co_researcher_knowledge_name}</b>
                              </h6>
                            </Col>
                            <Col xs={2} style={{ textAlign: "left" }}>
                              <FaChevronCircleDown
                                size={30}
                                className="arrow-down"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <hr />
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div>
      <Row className="div-detail">
        <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
          ความรู้
          {/* {t("community.knowledge_all")} {knowledge.length}{" "}
              {t("community.expertise.item")} */}
        </h5>
        <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
        <Row>
          {knowledge.map((data, index) => (
            <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
              <div
                className="card-output"
                style={{ backgroundColor: "#fff" }}
                onClick={() => onClickKn(data)}
              >
                <img
                  className="img-output"
                  src={
                    data.co_researcher_knowledge_image
                      ? `https://rmuts-rc.kims-rmuti.com/file-upload/co-knowledge-upload/${data.co_researcher_knowledge_image}`
                      : no_img_product
                  }
                  onError={(e) =>
                    (e.target.onerror = null)((e.target.src = no_img_product))
                  }
                  alt="output_image"
                />
                <div className="output-descreption">
                  <Row>
                    <Col xs={10}>
                      <h6>
                        <b>{data.co_researcher_knowledge_name}</b>
                      </h6>
                    </Col>
                    <Col xs={2} style={{ textAlign: "left" }}>
                      <FaChevronCircleDown size={30} className="arrow-down" />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        {Object.keys(knowledge_district).length ? (
          <DetailPage dataKn={knowledge_district} />
        ) : null}
        {Object.keys(knowledge_community).length ? (
          <DetailPage dataKn={knowledge_community} />
        ) : null}
        {Object.keys(knowledge_phi).length ? (
          <DetailPage dataKn={knowledge_phi} />
        ) : null}

        <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
        <div ref={divdetailKn}>{knowledgediv}</div>
      </Row>
    </div>
  );
}
