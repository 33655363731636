import React, { useEffect, useState, useRef } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import "../Css/mapView.scss";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { FaChevronCircleDown } from "react-icons/fa";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import no_img_product from "../../images/no_img_product.png";
import { apiUrl } from "../../config/api.json";
const YoutubeEmbed = ({ embedId }) => {
  const vdo_id = getId(embedId);
  return (
    <div className="video-responsive">
      <iframe
        width="600"
        height="480"
        src={`https://www.youtube.com/embed/${vdo_id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function ProjectPage(props) {
  const { co_researcher_id, classes } = props;
  const [publication, setpublication] = useState([]);
  const [publicationDetail, setpublicationDetail] = useState([]);
  const divdetailpublication = useRef();
  const [patent, setpatent] = useState([]);
  let id = atob(co_researcher_id);

  const [valuePage1, setValuePage1] = useState(0);

  const handleChangePage1 = (event, newValue) => {
    setValuePage1(newValue);
  };

  const getPatent = async () => {
    const data = await axios.get(
      `${apiUrl}/api/get/co-researcher/patent/${id}`
    );
    setpatent(data.data);
  };

  const getPublication = async () => {
    const data = await axios.get(
      `${apiUrl}/api/get/co-researcher/publication-group/${id}`
    );
    setpublication(data.data);
  };

  const onClickPu = (index) => {
    setpublicationDetail([publication[index]]);
    divdetailpublication.current.scrollIntoView({ behavior: "smooth" });
  };

  const publicationdiv = publicationDetail.map((listValue, index) => {
    var img = listValue.co_researcher_publication_image;

    const imagesPu = [];
    if (listValue.co_researcher_publication_image) {
      imagesPu.push({
        original: listValue.co_researcher_publication_image,
        thumbnail: listValue.co_researcher_publication_image,
      });
    }

    listValue.images.forEach(function (listitem) {
      if (listitem.co_publication_image_name) {
        imagesPu.push({
          original: listitem.co_publication_image_name,
          thumbnail: listitem.co_publication_image_name,
        });
      }
    });
    return (
      <div>
        <div className="blog-card " style={{ padding: "2rem" }}>
          <Row>
            <Col sm={12}>
              <div className="description">
                <h1>{listValue.co_researcher_publication_name_th}</h1>

                <hr style={{ padding: "0 3rem" }} />
              </div>
            </Col>
            <Col sm={12} md={6}>
              {listValue.images.length > 1 ? (
                <ImageGallery
                  showThumbnails={true}
                  autoPlay={true}
                  showPlayButton={false}
                  items={imagesPu}
                />
              ) : (
                <img
                  className="img-radius"
                  src={img}
                  onError={(e) =>
                    (e.target.onerror = null)((e.target.src = no_img_product))
                  }
                  alt="img"
                  style={{ maxWidth: "100%" }}
                />
              )}
            </Col>
            <Col sm={12} md={6}>
              <div className="description">
                <h5>{listValue.co_researcher_publication_type_name}</h5>
                <p>
                  <b>ประเทศ : </b> {listValue.co_researcher_publication_country}
                </p>
                <p>
                  <b>วันที่ตีพิมพ์ : </b>{" "}
                  {listValue.co_researcher_publication_date
                    ? new Date(
                        listValue.co_researcher_publication_date
                      ).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        weekday: "long",
                      })
                    : " - "}
                </p>
                <p>
                  <b>ฉบับที่ : </b> {listValue.co_researcher_publication_issue}
                </p>
                <p>
                  <b>เอกสารประกอบ : </b>{" "}
                  {listValue.co_researcher_publication_attachment ? (
                    <a
                      target="_blank"
                      href={`https://rmuts-rc.kims-rmuti.com/file-upload/co_publication-upload/${listValue.co_researcher_publication_attachment}`}
                      rel="noreferrer"
                    >
                      {listValue.co_researcher_publication_attachment}
                    </a>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  });

  useEffect(() => {
    getPatent();
    getPublication();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        className={classes.appbar}
        style={{ borderRadius: "10px 10px 0 0", paddingTop: "2rem" }}
      >
        <Tabs
          value={valuePage1}
          onChange={handleChangePage1}
          variant="scrollable"
          scrollButtons="on"
          TabIndicatorProps={{
            style: { background: "#30acdc" },
          }}
          classes={{
            root: classes.abRoot,
            positionStatic: classes.abStatic,
          }}
          textColor="secondary"
          aria-label="scrollable tabs menu"
        >
          <Tab
            label="ทรัพย์สินทางปัญญา"
            className={classes.customLabel}
            {...a11yProps(0)}
          />
          <Tab
            label="เอกสารเผยแพร่"
            className={classes.customLabel}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <div className="div-expertise">
        <TabPanel value={valuePage1} index={0}>
          {patent.length ? (
            <></>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>

        <TabPanel value={valuePage1} index={1}>
          {publication.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                เอกสารเผยแพร่ทั้งหมด {publication.length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
              <Row>
                {publication.map((data, index) => (
                  <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                    <div
                      className="card-output"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => onClickPu(index)}
                    >
                      <img
                        className="img-output"
                        src={
                          data.co_researcher_publication_image
                            ? data.co_researcher_publication_image
                            : no_img_product
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = no_img_product;
                        }}
                        alt="output_image"
                      />
                      <div className="output-descreption">
                        <Row>
                          <Col xs={10}>
                            <h6>
                              <b>{data.co_researcher_publication_name_th}</b>
                            </h6>
                          </Col>
                          <Col xs={2} style={{ textAlign: "left" }}>
                            <FaChevronCircleDown
                              size={30}
                              className="arrow-down"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
              <div
                ref={divdetailpublication}
                style={{ margin: "2rem -0.7rem -2.4rem -0.7rem" }}
              >
                {publicationdiv}
              </div>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>
      </div>
    </div>
  );
}
