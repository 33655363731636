import { Row, Col } from "reactstrap";
import React, { useState } from "react";
import { FcManager } from "react-icons/fc";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/dist/css/splide.min.css";
import { withRouter } from "react-router";
import axios from "axios";
import background_product from "../images/bg_product.png";
import no_img_research from "../images/no_img_research.png";
import no_img_co_research from "../images/no_img_co_research.png";
import no_img_network from "../images/no_img_network.png";
import { useTranslation } from "react-i18next";
import "./Css/innovation.scss";
import { Link } from "react-router-dom";
import Divloading from "./Loading/Divloading";
import { apiUrl } from "../config/api.json";

function Patent(props) {
  const [loading_re, setloading_re] = useState(true);
  const [loading_co, setloading_co] = useState(true);
  const [loading_op, setloading_op] = useState(true);

  const [researcher, setresearcher] = useState([]);
  const [community, setcommunity] = useState([]);
  const [co_operation, setco_operation] = useState([]);
  const { t } = useTranslation();
  const getresearcher = () => {
    axios
      .get(`${apiUrl}/api/get/patent?title=`)
      .then((res) => {
        setresearcher(res.data);
        setloading_re(false);
      })
      .catch((err) => {
        console.log(err);
        setloading_re(false);
      });
  };

  const getcommunity = () => {
    axios
      .get(`${apiUrl}api/get/co-researcher/commupatent/list?title=`)
      .then((res) => {
        setcommunity(res.data);
        setloading_co(false);
      })
      .catch((err) => {
        console.log(err);
        setloading_co(false);
      });
  };

  const getco_operation = () => {
    axios
      .get(`${apiUrl}/api/get/co-researcher/cooppatent/list?title=`)
      .then((res) => {
        setco_operation(res.data);
        setloading_op(false);
      })
      .catch((err) => {
        console.log(err);
        setloading_op(false);
      });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getresearcher();
    getcommunity();
    getco_operation();
  }, []);

  let researcherdata = researcher.map(function (listValue, i) {
    var url_image = "";
    if (listValue.patent_images) {
      url_image = `https://rmuts-rc.kims-rmuti.com/file-upload/patent-upload/${listValue.patent_images}`;
    } else {
      url_image = no_img_research;
    }
    // console.log(url_image);
    const url = `/PatentDetail?type=researcher&patent_name=${listValue.patent_name_th}`;
    return (
      <SplideSlide key={i}>
        <div className="card card-bg-innovation card-innovation">
          <div className="card__image-holder">
            <img
              className="card__image img-innovation"
              src={url_image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = no_img_research;
              }}
              alt="co_innovation_image"
            />
          </div>
          <div className="card-innovation-title">
            <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
              {listValue.patent_name_th}
            </h2>
            <small className="innovation">
              <FcManager size={15} /> : {listValue.patent_application}
            </small>
          </div>
          <div className="descriptions">
            <h5 className="title-hover">{listValue.patent_name_th}</h5>
            <p>
              {t("patent.registration_date")} :
              {listValue.patent_date
                ? new Date(listValue.patent_date).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : " "}
            </p>
            <p>
              {t("patent.request_number")} : {listValue.patent_request}
            </p>
            <p>
              <p>
                {t("patent.registration_applicant")} :{" "}
                {listValue.patent_application}
              </p>
            </p>
            <Link to={url} className="btn-innovation">
              {t("innovation.seemore")}
            </Link>
          </div>
        </div>
      </SplideSlide>
    );
  });

  let communitydata = community.map(function (listValue, i) {
    var url_image = "";
    if (listValue.patent_images) {
      url_image = `https://rmuts-rc.kims-rmuti.comfile-upload/patent-upload/${listValue.patent_images}`;
    } else {
      url_image = no_img_co_research;
    }

    const url = `/PatentDetail?type=community&patent_name=${listValue.patent_name_th}`;
    return (
      <SplideSlide key={i}>
        <div className="card card-bg-innovation card-innovation">
          <div className="card__image-holder">
            <img
              className="card__image img-innovation"
              src={url_image}
              onError={(e) =>
                (e.target.onerror = null)((e.target.src = no_img_research))
              }
              alt="co_innovation_image"
            />
          </div>
          <div className="card-innovation-title">
            <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
              {listValue.patent_name_th}
            </h2>
            <small className="innovation">
              <FcManager size={15} /> : {listValue.patent_application}
            </small>
          </div>
          <div className="descriptions">
            <h5 className="title-hover">{listValue.patent_name_th}</h5>
            <p>
              {t("patent.registration_date")} :
              {listValue.patent_date
                ? new Date(listValue.patent_date).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : " "}
            </p>
            <p>
              {t("patent.request_number")} : {listValue.patent_request}
            </p>
            <p>
              <p>
                {t("patent.registration_applicant")} :{" "}
                {listValue.patent_application}
              </p>
            </p>
            <Link to={url} className="btn-innovation">
              {t("innovation.seemore")}
            </Link>
          </div>
        </div>
      </SplideSlide>
    );
  });

  let networkdata = co_operation.map(function (listValue, i) {
    var url_image = "";
    if (listValue.co_patent_image) {
      url_image = `https://rmuts-rc.kims-rmuti.com/file-upload/co_patent-upload/${listValue.co_patent_image}`;
    } else {
      url_image = no_img_network;
    }

    const url = `/PatentDetail?type=conetwork&patent_name=${listValue.co_patent_name_th}`;
    return (
      <SplideSlide key={i}>
        <div className="card card-bg-innovation card-innovation">
          <div className="card__image-holder">
            <img
              className="card__image img-innovation"
              src={url_image}
              onError={(e) =>
                (e.target.onerror = null)((e.target.src = no_img_research))
              }
              alt="co_innovation_image"
            />
          </div>
          <div className="card-innovation-title">
            <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
              {listValue.co_patent_name_th}
            </h2>
            <small className="innovation">
              <FcManager size={15} /> : {listValue.co_patent_registered_name}
            </small>
          </div>
          <div className="descriptions">
            <h5 className="title-hover">{listValue.co_patent_name_th}</h5>
            <p>
              {t("patent.registration_date")} :
              {listValue.co_patent_date
                ? new Date(listValue.co_patent_date).toLocaleDateString(
                    "th-TH",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )
                : " "}
            </p>
            <p>
              {t("patent.request_number")} : {listValue.co_patent_request}
            </p>
            <p>
              <p>
                {t("patent.registration_applicant")} :{" "}
                {listValue.co_patent_registered_name}
              </p>
            </p>
            <Link to={url} className="btn-innovation">
              {t("innovation.seemore")}
            </Link>
          </div>
        </div>
      </SplideSlide>
    );
  });

  const options_top = (count) => {
    return {
      type: count <= 4 ? "slide" : "loop",
      gap: "1rem",
      perPage: 4,
      perMove: 1,
      interval: 3000,
      pagination: false,
      speed: 2000,
      start: 10,
      breakpoints: {
        2560: {
          perPage: 4,
        },
        1440: {
          perPage: 3,
        },
        1024: {
          perPage: 3,
        },
        768: {
          perPage: 2,
        },
        640: {
          perPage: 1,
        },
      },
      autoplay: true,
      pauseOnHover: true,
      resetProgress: false,
    };
  };

  const options = {
    type: "loop",
    gap: "1rem",
    perPage: 5,
    perMove: 1,
    interval: 2500,
    pagination: false,
    speed: 2000,
    breakpoints: {
      2560: {
        perPage: 5,
      },
      1440: {
        perPage: 4,
      },
      1024: {
        perPage: 4,
      },
      768: {
        perPage: 3,
      },
      640: {
        perPage: 1,
      },
    },
    autoplay: true,
    pauseOnHover: true,
    resetProgress: false,
  };

  return (
    <div className="body-detail">
      <Row className="row-innovation" style={{ paddingTop: "30px" }}>
        <Col md={12}>
          <div>
            <div className="bg-filter">
              <div className="cards">
                {loading_re ? (
                  <Divloading />
                ) : (
                  <Row style={{ width: "100%" }}>
                    {researcher.length > 0 ? (
                      <Col md={12} style={{ textAlign: "right" }}>
                        <a
                          class="custom-btn btn-14"
                          style={{
                            width: "max-content",
                            textAlign: "center",
                            color: "white",
                            fontFamily: "Prompt",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          href={`/monitoring/PatentAll?type=researcher`}
                        >
                          <span style={{ fontSize: "16px" }}>
                            {t("innovation.viewall")}&nbsp;({researcher.length})
                          </span>
                        </a>
                      </Col>
                    ) : null}
                    <Col sm={4} md={4} lg={3}>
                      <div className="title-center">
                        <div
                          className="bg-title"
                          style={{
                            background: "#56bbe2",
                            borderRadius: "10px",
                          }}
                        >
                          <Row>
                            <Col md={12}>
                              <h1
                                className="hit-the-floor"
                                style={{ color: "white", fontFamily: "Prompt" }}
                              >
                                {t("patent.researcher")}
                              </h1>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>

                    <Col sm={8} md={8} lg={9}>
                      {researcher.length > 0 ? (
                        <Splide
                          options={options_top(researcher.length)}
                          aria-labelledby="autoplay-example-heading"
                        >
                          {researcherdata}
                        </Splide>
                      ) : (
                        <div className="inno-notdata">
                          <p> {t("notfound")}</p>
                        </div>
                      )}
                    </Col>
                    {researcher.length > 4 ? (
                      <Col md={12}>
                        {researcher.length > 0 ? (
                          <Splide
                            options={options}
                            aria-labelledby="autoplay-example-heading"
                          >
                            {researcherdata}
                          </Splide>
                        ) : null}
                      </Col>
                    ) : null}
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} style={{ marginTop: "2rem" }}>
          <div>
            <div className="bg-filter">
              <div className="cards">
                {loading_co ? (
                  <Divloading />
                ) : (
                  <Row style={{ width: "100%" }}>
                    {community.length > 0 ? (
                      <Col
                        md={12}
                        style={{ textAlign: "right", marginBottom: "1rem" }}
                      >
                        <Link
                          class="custom-btn btn-14"
                          style={{
                            width: "max-content",
                            textAlign: "center",
                            color: "white",
                            fontFamily: "Prompt",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          to={`/PatentAll?type=commuity`}
                        >
                          <span style={{ fontSize: "16px" }}>
                            {t("innovation.viewall")}&nbsp;({community.length})
                          </span>
                        </Link>
                      </Col>
                    ) : null}
                    <Col sm={4} md={4} lg={3} className="title-innovation">
                      <div className="title-center">
                        <div
                          className="bg-title"
                          style={{
                            background: "#56bbe2",
                            borderRadius: "10px",
                          }}
                        >
                          <Row>
                            <Col md={12}>
                              <h1
                                className="hit-the-floor"
                                style={{ color: "white", fontFamily: "Prompt" }}
                              >
                                {t("patent.community")}
                              </h1>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col sm={8} md={8} lg={9}>
                      {community.length > 0 ? (
                        <Splide
                          options={options_top}
                          aria-labelledby="autoplay-example-heading"
                        >
                          {communitydata}
                        </Splide>
                      ) : (
                        <div className="inno-notdata">
                          <p> {t("notfound")}</p>
                        </div>
                      )}
                    </Col>
                    <Col md={12}>
                      {community.length > 4 ? (
                        <Splide
                          options={options}
                          aria-labelledby="autoplay-example-heading"
                        >
                          {communitydata}
                        </Splide>
                      ) : null}
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} style={{ marginTop: "2rem" }}>
          <div>
            <div className="bg-filter">
              <div className="cards">
                {loading_op ? (
                  <Divloading />
                ) : (
                  <Row style={{ width: "100%" }}>
                    {co_operation.length > 0 ? (
                      <Col
                        md={12}
                        style={{ textAlign: "right", marginBottom: "1rem" }}
                      >
                        <Link
                          class="custom-btn btn-14"
                          style={{
                            width: "max-content",
                            textAlign: "center",
                            color: "white",
                            fontFamily: "Prompt",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          to={`/PatentAll?type=conetwork`}
                        >
                          <span style={{ fontSize: "16px" }}>
                            {t("innovation.viewall")}&nbsp;(
                            {co_operation.length})
                          </span>
                        </Link>
                      </Col>
                    ) : null}
                    <Col sm={4} md={4} lg={3} className="title-innovation">
                      <div className="title-center">
                        <div
                          className="bg-title"
                          style={{
                            background: "#56bbe2",
                            borderRadius: "10px",
                          }}
                        >
                          <Row>
                            <Col md={12}>
                              <h1
                                className="hit-the-floor"
                                style={{ color: "white", fontFamily: "Prompt" }}
                              >
                                {t("patent.co_operation")}
                              </h1>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col sm={8} md={8} lg={9}>
                      {co_operation.length > 0 ? (
                        <Splide
                          options={options_top}
                          aria-labelledby="autoplay-example-heading"
                        >
                          {networkdata}
                        </Splide>
                      ) : (
                        <div className="inno-notdata">
                          <p> {t("notfound")}</p>
                        </div>
                      )}
                    </Col>
                    {co_operation.length > 4 ? (
                      <Col md={12}>
                        <Splide
                          options={options}
                          aria-labelledby="autoplay-example-heading"
                        >
                          {networkdata}
                        </Splide>
                      </Col>
                    ) : null}
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Patent);
